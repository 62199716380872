.figure-panel__border-width-input {
	border: none;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
}

.figure-panel__corner-radius-wrapper {
	display: flex;
	align-items: center;
	width: 68px;
	height: 32px;
	background: #FFFFFF;
	border: 1px solid #EEEEEE;
	border-radius: 4px;
	padding: 8px;
	margin: 0 2px 0 8px;
	box-sizing: border-box;
}

.figure-panel__corner-radius-svg-icon {
	margin-right: 4px;
	stroke-width: 2px;
	width: 30px;
	height: 12px;
}

.frame-panel__corner-radius-input {
	border: none;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
}

.frame-panel__corner-radius-wrapper {
	display: flex;
	align-items: center;
	width: 80px;
	height: 32px;
	background: #FFFFFF;
	border: 1px solid #EEEEEE;
	border-radius: 4px;
	padding: 8px;
	margin: 0 2px 0 8px;
	box-sizing: border-box;
}

.frame-panel__corner-radius-svg-icon {
	margin-right: 8px;
	stroke-width: 2px;
	width: 12px;
	height: 12px;
}
