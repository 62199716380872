.select {
	position: relative;
}
.title {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 14.4px;
	text-align: left;
	color: rgba(127, 129, 132, 1);
	margin-bottom: 9px;
}
.selected {
	width: 100%;
	display: grid;
	grid-template-columns: auto 10px;
	border: 1px solid rgb(237, 237, 237);
	border-radius: 4px;
	padding: 7px 11px;
	color: rgba(39, 39, 39, 1);
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.8px;
	text-align: left;

	button {
		text-align: left;
		background: transparent;
		cursor: pointer;
	}
}

.options {
	background: rgba(255, 255, 255, 1);
	display: flex;
	flex-direction: column;
	border-radius: 9.44px;
	padding: 6px;
	position: absolute;
	width: 100%;
	z-index: 10;

	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
	&:focus {
		border: 1px solid rgb(237, 237, 237);
	}
}
.option {
	text-align: left;
	ont-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.8px;
	padding: 7px 11px;
	text-align: left;
	border-radius: 4px;
	background: transparent;
	cursor: pointer;

	&:hover {
		background: #f2f2f2;

	}
}
.checked {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	cursor: pointer;
	padding: 7px 11px;

	&:hover {
		background: #f2f2f2;

	}
}

