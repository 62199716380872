@import "../../base/default_variables";

.table-panel__fill {
	@extend %fc;
	flex-direction: row-reverse;
	margin-bottom: 6px;
	h4 {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 130%;
	}
}
