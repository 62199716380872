.ai-form {
    display: flex;
    flex-direction: column;
    width: 700px;
    margin-bottom: 24px;
    padding: 0px 24px;

    textarea {
        resize: vertical;
        border: 1px solid rgba(237, 237, 237, 1);
        background: rgba(255, 255, 255, 1);
        min-height: 60px;
        padding: 7px 11px;
        border-radius: 4px;
    }
    textarea::-webkit-input-placeholder {
        color: rgba(193, 193, 193, 1);
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    textarea:-moz-placeholder { /* Firefox 18- */
        color: rgba(193, 193, 193, 1);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    textarea::-moz-placeholder {  /* Firefox 19+ */
        color: rgba(193, 193, 193, 1);
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    textarea:-ms-input-placeholder {
        color: rgba(193, 193, 193, 1);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
    }
    textarea:focus-visible,
    select:focus-visible {
        outline: 1px solid rgba(127, 129, 132, 1);
    }

    textarea::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
    textarea::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
    textarea:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
    textarea:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
    textarea:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
    textarea:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
    textarea:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
    textarea:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

    select {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        padding: 7px 11px;
        text-align: left;
        border-radius: 4px;
        border: 1px solid rgba(237, 237, 237, 1);
        background: transparent;
        &:active {
            border-color: #5b9bd5;
        }
        & option:focus {
            color: #68ff42;
        }

    }

    .select option:hover {
    color: #68ff42;
    }

    .error {
    color: rgba(255, 54, 54, 1);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    }
}

.submit_icon {
    cursor: pointer;
}

.ai-form_label {
	display: flex;
	flex-direction: column;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 34px;
	text-align: left;
	color: rgba(127, 129, 132, 1);
	margin-bottom: 7px
}

.ai-response {
    padding: 13px 24px 13px 24px;
    margin-bottom: 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2.36px 4.72px 0 rgba(0, 0, 0, 0.02);
    border-radius: 3px 9.44px 9.44px 9.44px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.92px;
    text-align: left;
    color: rgba(39, 39, 39, 1);
    user-select: text;
}
.aiSubmit {
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 6px 16px;
    width: 130.77px;
    height: 31px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid rgb(237, 237, 237);
    display: flex;
    &:hover {
        border: 1px solid rgba(135, 135, 135, 1)
    }
    .aiSubmit_btn {
        background: transparent;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        border: none;
        padding: 0;
        margin-right: 4px;
        cursor: pointer;
    }
    .aiSubmit_icon {
        cursor: pointer;
    }
}
.response {
  padding: 13px 24px 13px 24px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2.36px 4.72px 0 rgba(0, 0, 0, 0.02);
  border-radius: 3px 9.44px 9.44px 9.44px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.92px;
  text-align: left;
  color: rgba(39, 39, 39, 1);
}
.responsesWrapper {
    background: #eaedf1;
    padding: 19px 24px;
	max-height: 400px;
	overflow: auto;
}
