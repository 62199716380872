.Toastify__toast--success {
    border: 2px solid #249C5C;
}
.Toastify__toast--error {
    border: 2px solid #FF3636 !important;
}
.Toastify__toast--warning {
    border: 2px solid #F2B519  !important;
}
.Toastify__toast--info {
    border: 2px solid #0075FF  !important;
}

.Toast__filled-warning {
    background-color: #F2B519 !important;
}
.Toast__filled-success {
     background-color: #249C5C !important;
 }
.Toast__filled-error {
    background-color: #FF3636 !important;
}
.Toast__filled-info {
    background-color: #0075FF !important;
}

.Toastify__toast--warning, .Toastify__toast--error, .Toastify__toast--success {
    border-radius: 4px !important;
}
.Toastify__close-button {
    position: absolute;
    width: 25px;
    height: 25px;
    transform: scale(1.2);
    right: 5px;
    top: 5px;
}
.Toastify__toast {
    padding: 0 !important;
}
.Toastify__toast-container {
    padding: 0 !important;
    color: #fff;
    width: 420px !important;
}
.Toast__bottom {
    border-radius: 4px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    border: 1px solid #BEBEBE !important;
    background: #F4F3FE !important;
    min-height: 0 !important;
    padding: 6px !important;

    .Toastify__toast {
        padding: 0 !important;
        background: #F4F3FE !important;
        border-radius: 4px !important;
    }

    .Toastify__toast-body {
        padding: 0 !important;
        border-radius: 4px !important;
        margin: 0;

        div {
            .notifications_wrapper__QEQ7B {
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    .notifications_description__D2x1w {
                        display: none;
                    }

                    .notifications_title__uuK2s {
                        font-family: 'Inter', sans-serif !important;
                        font-style: normal !important;
                        font-weight: 400 !important;
                        font-size: 16px !important;
                        color: #212121 !important;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
            }
        }

        .notifications_picture__jD0JK {
            display: none;
        }
    }
}
