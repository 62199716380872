@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
    padding-top: 28px;
    width: 448px;
	z-index: 01923870123470;
}

.createTemplateWrapper {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.preview {
    max-width: 375px;
    max-height: 375px;
    margin-right: 20px;
    margin-top: 28px;
    padding: 60px 30px;
    border: 1px solid #EDEDED;
    border-radius: 16px;
    background: $background-preview;

}

.content {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 22px;

    &::-webkit-scrollbar {
	width: 6px;
	background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
	background: #99C8FF;
	border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EBEBEB;
    }
}

.title {
    font-weight: 500;
    font-size: 23px;
    line-height: 130%;
    color: $grey850;
}

.btn {
    display: block;
    margin-top: 24px;
}

.more {
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #939393;
    margin-top: 16px;
    margin-bottom: 16px;
    cursor: pointer;

    .text {
	margin-right: 8px;
    }
}

.area {
    position: relative;
    width: 100%;
    margin-top: 8px;

    textarea {
	font-weight: 400;
	font-size: 18px;
	color: $grey850;
	width: 100%;
	max-width: 420px;
	max-height: 150px;
	border-radius: 4px;
	border-color: #EDEDED;
	padding: 24px;
	overflow: hidden;
	min-width: 50%;

	&:focus {
	    outline: none;
	}

    }

    .placeholder {
	position: absolute;
	bottom: 16px;
	left: 24px;
	font-weight: 400;
	font-size: 13px;
	color: #939393;
    }
}

.charCounter {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #939393;
    margin-top: 8px;
}
.loader {
	align-items: center;
	height: 400px;
	justify-content: center;
}
