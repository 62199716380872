@import "/src/styles/color";

.search {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 4px 0 auto;
    background: $white;
    border: 1px solid #EDEDED;
    border-radius: 320px;

    &.focus {
	path {
	    stroke: $blueA600;
	    transition: all .2s ease-in-out;
	}
    }

    > svg {
	transition: all .2s ease-in-out;
	fill: $white;

	&.focus {
	    stroke: $blueA600;
	}
    }

    > input {
	color: $black;
	font-size: 14px;
	outline: none;
	border: none;
	background: $white;
	width: 100%;
	padding: 13px 25px 13px 0;
	border-radius: 0 350px 350px 0;
    }

    > .clear-box {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 0 0 12px;
	transition: all .2s ease-in-out;
	opacity: 0;
	pointer-events: none;

	&.show {
	    opacity: 1;
	    pointer-events: all;
	}

	> svg {
	    fill: $white;
	}

	&:hover {
	    cursor: pointer;

	    > svg {
		stroke: $blueA600;
	    }
	}
    }
}

.search_icon {
    padding: 13px 0 13px 25px;
    margin-right: 19px;
}
