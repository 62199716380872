html, body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	line-height: 1;
}

#root, .App, .sketch-manipulator {

}
.hide {
	display: none;
}
body .show {
	display: block;
}

html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

p {
	margin-bottom: 0;
}

