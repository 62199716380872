@import "/src/styles/color";

.collection {
    display: flex;
    flex: 1;
}

.left_side_bar {
    border-right: 1px solid $grey250;
    display: flex;
    flex-direction: column;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -120%);
}

.version_text {
    position: sticky;
    bottom: 0;
    margin-top: auto;
    color: $grey550;
    padding: 0 0 18px 28px;
}
