@import "/src/styles/color";

.wrapper {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;

    > svg {
	margin-right: 25px;
    }
}

.textContainer {
    position: relative;
    width: 348px;
    height: 97px;

    > div {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 80px;
	font-weight: 500;
    }
}

.lower {
    color: $white;
    -webkit-text-stroke: 2px $black;
}

.higher {
    white-space: nowrap;
    overflow: hidden;
    color: $black;
    transition: width 2s;
    width: 348px;
}

.animate {
    width: 0;
}
