@import "../../base/default_variables";
@import "./__modules";

.panel-modules {
    background: #FFFFFF;
    border-radius: $borderRadiusPanelElement;
    padding: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);

    & > div {
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        height: 58px;
        justify-content: center;
        margin: 0;
        position: relative;
        width: 58px;
        stroke: #7f8184;
    }

    &:hover svg {
        stroke: $panelElementHover;
        background: $panelElementHoverBG;
    }

    &:hover > div {
        background: $panelElementHoverBG;
    }
}
