@import "/src/styles/color";

.wrapper {
    font-weight: 700;
    font-size: 16px;
}

.active {
    color: #34A853;
}

.disabled {
    color: #F14D4D;
}
