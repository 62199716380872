.search {
    border: none;
}

.list {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

