@import "/src/styles/color";

.change_status {
    border: 1px solid $grey200;
    background-color: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    gap: 8px;
    top: 40px;
    left: -4px;
    z-index: 10;
}

.status {
    cursor: pointer;
}
