@import "../../base/default_variables";
@import "./__zoom";
@import "./_zoom-size-decrease";
@import "./_zoom-size-increase";

.panel-zoom {
	position: fixed;
	top: auto;
	right: auto;
	bottom: 20px;
	left: 20px;
	background: #FFFFFF;
	border-radius: $borderRadiusPanelElement;
	padding: 4px;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
}
