@import "/src/styles/color";

.wrapper {
    padding: 8px 16px;
    border: 1px solid $grey250;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.text {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    max-width: 354px;
    color: $black;
    margin-right: 32px;
    margin-left: 16px;
}

.picture {
    width: 64px;
    height: 64px;
}
