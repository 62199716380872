.context-menu_table {
	.context-menu__section {
		padding: 8px 24px;

	}
	.context-menu__row {
		justify-content: flex-start;
		padding: 8px 0;
		border-bottom: 1px solid transparent;
		border-top: 1px solid transparent;

		svg {
			margin-right: 11px;
		}

		&:hover {
			border-bottom: 1px solid #EDEDED;
			border-top: 1px solid #EDEDED;

		}
		&:hover .context-menu__row-caption {
			color: #272727;
		}
	}
}