@import "../../../base/default_variables";

.pages-container__highlight-area {
  position: absolute;
  left: var(--left);
  top: var(--top);
  width: var(--width);
  height: var(--height);
  background: $highlight_area_background;
  border: 1px solid $highlight_area_border;
  z-index: 999;
}
