@import "color";

@keyframes move-empty-item {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@mixin shadow-small {
  box-shadow: 0 0 1px 1px $grey200;
}

.shadow-small {
  box-shadow: 0 0 1px 1px $grey200;
}

@mixin shadow-medium {
  box-shadow: 0 0 4px 2px $grey300;
}

.shadow-medium {
  box-shadow: 0 0 4px 2px $grey300;
}

@mixin shadow-large {
  box-shadow: 0 0 6px 3px $grey300;
}

.shadow-large {
  box-shadow: 0 0 6px 3px $grey300;
}

.background-blur {
  background-color: rgba(0, 0, 0, 0.05);
}

@supports(backdrop-filter: blur(4px)) {
  .background-blur {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
  }
}

.border {
  border-radius: 5px;
}

.display-none {
  display: none !important;
}

.invisible-input {
  display: none;
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.skeleton-loading {
  position: relative;
  border-radius: inherit;
  background: #fafafa !important;
  overflow: hidden !important;
  color: #fafafa !important;

  &::after {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    border-radius: 20px;
    background: linear-gradient(90deg,
        #fafafa 0%,
        #f4f4f4 50%,
        #fafafa 100%);
    animation: move-empty-item 1s infinite;
  }
}

.trigger-remove-action-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.line {
  height: 1px;
  width: 100%;
  background: $grey200;
  margin: 5px auto;
}

.ui-micro-input-num {
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  color: $grey400;

  >input {
    width: 30px;
    margin: 5px;
    padding: 4px;
    text-align: center;
    border: none;
    border-radius: 5px;
    outline: none;
    transition: all .2s ease-in-out;
    box-shadow: 0 0 1px 1px $grey200;
    color: $grey600;

    &:hover {
      box-shadow: 0 0 4px 2px $grey300;
    }
  }

  >span {
    color: $grey800;
  }
}

.ui-select-first {
  padding: 7px 14px;
  border: 1px solid $grey200;
  border-radius: 6px;
  font-size: .9em;
  transition: all .2s ease-in-out;

  &:hover {
    border: 1px solid $grey450;
  }

  >.head {}
}

.ui-input-with-select {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border: 1px solid $grey200;
  border-radius: 6px 6px 0 0;
  width: 50px;
  font-size: .9em;
  transition: all .2s ease-in-out;

  &:hover {
    border: 1px solid $grey450;
  }

  &.close {
    border-radius: 6px;

    >.body {
      max-height: 0;
      padding: 0;
      border: 0;
    }
  }

  >.head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    >input {
      width: 100%;
      outline: none;
      border: 1px solid transparent;
      font-size: .96em;
    }

    >svg {
      fill: $grey450;
      padding-left: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  >.body {
    overflow: hidden;
    max-height: 9999px;
    position: absolute;
    top: 100%;
    left: -1px;
    display: flex;
    flex-direction: column;
    background: $white;
    border: 1px solid $grey200;
    width: 100%;
    border-radius: 0 0 6px 6px;
    padding: 4px 0;
    transition: all .2s ease;

    >span {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      padding: 4px 10px;
      margin: 1px 0;
      transition: all .2s ease;
      width: 100%;
      box-sizing: border-box;

      &:hover {
        cursor: pointer;
        border-top: 1px solid $grey200;
        border-bottom: 1px solid $grey200;
        width: 100%;
      }
    }
  }
}

.ui-input-with-label {
  display: flex;
  flex-direction: column;
  padding: 14px 20px;
  border: 1px solid $grey370;
  border-radius: 6px;
  margin-bottom: 12px;

  >input {
    outline: none;
    border: none;
    font-size: 1.1em;
    margin: 0;
    padding: 0 0 8px;

    &[disabled="true"] {
      user-select: none;
      color: $grey550;
    }
  }

  >label {
    color: $grey550;
    font-size: .76em;
  }
}

.ui-button-grey {
  padding: 13px 22px;
  border: 2px solid #EDEFF1;
  font-weight: 700;
  background: $white;
  color: #868686;
  border-radius: 99px;
  transition: all .2s ease-in-out;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background: #EDEFF1;
  }
}

.ui-button-white {
  padding: 11px 22px 13px;
  border: 2px solid #D6E9FF;
  font-weight: 700;
  background: $white;
  color: #0075FF;
  border-radius: 99px;
  transition: all .2s ease-in-out;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    border: 2px solid #0075FF;
    background: #0075FF;
    color: $white;
  }
}

// DEPRECATED
.ui-button-blue {
  font-size: .9em;
  padding: 13px 22px;
  background: $blueA600;
  color: $white;
  border-radius: 42px;
  font-weight: 700;
  font-style: normal;
  border: none;
  transition: all .2s ease-in-out;
  margin: 10px 0;
  outline: none;

  &:hover {
    cursor: pointer;
    background: $blue450;
  }
}

.gui-color-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 26px;
  height: 26px;
  border: 1px solid $grey200;
  border-radius: 6px;
  transition: all .2s ease-in-out;
  margin-right: 8px;

  &:hover {
    border: 1px solid $grey450;
  }

  &.transparent {
    >div {
      background: $white;

      >svg {
        opacity: 1;
      }
    }
  }

  >div {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid $grey450;

    >svg {
      fill: $redA400;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  >input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.ui-select-svg {
  display: flex;
  flex-direction: column;
  background: $white;
  border: 1px solid $grey200;
  border-radius: 6px;
  transition: border .6s;
  max-height: 999px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  >div {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 0 26px 0 14px;
    height: 22px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:hover {
      border-top: 1px solid $grey200;
      border-bottom: 1px solid $grey200;
    }

    &.select {
      order: -1;

      &:hover {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }
  }

  &.disable {
    max-height: 26px;
  }

  >svg {
    position: absolute;
    right: 9px;
    top: 10px;
    fill: $grey450;
    pointer-events: none;
  }
}

.ui-context-menu {
  position: absolute;
  padding: 18px 25px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #EEEEEE;
  z-index: 10;

  h3 {
    font-weight: 500;
    font-size: 14px;
    color: #272727;
    margin: 0 0 12px 0;
  }

  >.cells-block {
    >div {
      display: flex;
      align-items: center;
      margin: 2px 0;

      >svg {
        margin: 2px 2px 2px 4px;
      }

      >p {
        margin: 4px;
        color: #272727;
        font-size: 14px;
      }
    }

    >button {
      width: 100%;
      padding: 4px;
      margin: 2px 0;
      color: #272727;
      font-size: 14px;
      background: none;
      border: 1px solid #EEEEEE;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
        background: #EEEEEE;
      }
    }
  }
}

.gui-font-paddings {
  position: relative;
  background: #F2F2F2;

  >.button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  >.modal {
    position: absolute;
    top: calc(100% + 16px);
    width: 402px;
    display: block;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 27px 40px;
    cursor: default;

    >p {
      font-weight: 600;
      font-size: 14px;
      color: #272727;
      padding: 0;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  &.disable {
    background: #FFFFFF;

    >.modal {
      display: none;
    }
  }
}

.gui-font-select {
  position: relative;
  font-size: 14px;
  height: 34px;
  width: 150px;
  border: 1px solid #EEEEEE;
  border-radius: 6px;

  >div:nth-child(1) {
    display: flex;
    margin: 7px 0 8px 14px;
    cursor: pointer;

    >p {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    >img {
      margin-left: auto;
      padding: 7px 12px 5px;
    }
  }

  >div:nth-child(2) {
    position: absolute;
    top: calc(100% + 2px);
    width: 190px;
    z-index: 10;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 6px;

    >div {
      position: relative;
      padding: 5px 14px 5px 27px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &:hover {
        cursor: pointer;
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
      }

      >img {
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.gui-font-size-select {
  position: relative;
  font-size: 14px;
  width: 73px;
  height: 34px;
  border: 1px solid #EEEEEE;
  border-radius: 6px;

  >div:nth-child(1) {
    display: flex;
    margin: 7px 0 8px 14px;
    cursor: pointer;

    >p {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    >img {
      margin-left: auto;
      padding: 7px 12px 5px;
    }
  }

  >div:nth-child(2) {
    position: absolute;
    top: calc(100% + 2px);
    width: 100%;
    z-index: 10;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 6px;

    >div {
      position: relative;
      padding: 5px 5px 5px 27px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &:hover {
        cursor: pointer;
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
      }
    }
  }
}

.gui-range-section {
  display: flex;

  >.range-block {
    position: relative;
    width: 219px;
    margin-right: 38px;

    >.line {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background: #CDCDCD;
      margin: 0;
    }

    >.blue-line {}

    >.circle {
      position: absolute;
      left: var(--position-left);
      top: 50%;
      transform: translateY(-50%);
      background: #FFFFFF;
      border: 1px solid #0075FF;
      border-radius: 100%;
      width: 21px;
      height: 21px;
    }
  }

  >input {
    outline: none;
    width: 60px;
    height: 33px;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    padding: 14px;
  }
}

.gui-text-block {

  >div {
    display: flex;
    align-items: center;
  }

  >.font-block {
    justify-content: center;

    >div {
      margin-right: 10px;
    }
  }

  >.text-style {
    padding-top: 8px;

    >.align {
      display: flex;
      align-items: center;

      >div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-right: 4px;
        transition: all .2s ease-in-out;
        background: $white;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background: $grey150;
        }

        &:active {
          background: $grey320;
        }

        &.active {
          background: $grey320;
        }

        >svg {
          fill: $grey850;
        }
      }
    }

    >.style {
      display: flex;
      align-items: center;

      >div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 4px;
        border-radius: 4px;
        border: 1px solid $grey200;
        padding: 8px;
        transition: all .2s ease-in-out;

        &:hover {
          cursor: pointer;
          background: #F2F2F2;
        }

        .active {
          background: $grey200;

          >svg {
            fill: #272727;
          }
        }

        >svg {
          fill: $grey650;
        }
      }
    }
  }
}

.gui-select-area {
  position: absolute;
  top: var(--offset-y);
  left: var(--offset-x);
  width: var(--area-width);
  height: var(--area-height);
  border: 1px solid #0075FF;
  background: rgba(0, 117, 255, .2);
}



