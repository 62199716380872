@import '/src/styles/color';

.wrapper {
    width: 100%;
    padding: 36px 0;
}

.title {
    font-weight: 600;
    font-size: 29px;
    color: $grey850;
}

.description {
    color: #999999;
    font-size: 12px;
    margin-top: 16px;
    font-weight: 500;
}

.title_wrapper {
    display: flex;
    align-items: start;
    margin-bottom: 7px;
    justify-content: space-between;
    margin-top: 28px;
}

.tags {
    margin-bottom: 24px;

    h3 {
	font-size: 23px;
	font-weight: 500;
	margin: 0 0 13px;
    }
}
