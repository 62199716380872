.t1001-select {
	position: relative;
	width: 138px;
	cursor: pointer;
	&__input-wrapper {
		background: #FFFFFF;
		border: 1px solid #EEEEEE;
		border-radius: 4px;
		height: 32px;
		padding: 0 16px;
		display: flex;
	}
	&__input {
		width: 100%;
		margin-right: 12px;
		.t1001-select__item {
			width: 100%;
			height: 100%;
			padding: 0;
		}
	}
	&__input-arrow {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__input-arrow.open svg {
		transform: rotate(180deg);
		transition: all 0.2s;
	}
	&__dropdown {
		display: none;
		position: absolute;
		width: 80px;
		background: #FFFFFF;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 12px 24px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 8px 0;
	}
	&__dropdown.open {
		display: block;

	}
	&__item {
		padding: 8px 16px;
		display: flex;
		align-items: center;
		height: 30px;
		svg {
			height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}
}
