@import "/src/styles/color";

.wrapper {
    padding: 36px 0 0 0;
}

.edit_block {
    margin-bottom: 40px;
    align-items: start;
}

.historyWrapper {
    margin-right: 40px;
}

.picture {
    width: 397px;
    height: 197px;
    border: 1px solid $disabled;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: 0.3s ease;

    img {
	width: 100%;

	&:hover {
	    transition: 0.3s ease;
	    opacity: 0.7;
	}
    }
}

.preview {
    justify-content: center;
    background-color: #EAEDF1;
    padding: 136px 0 136px 0;
}

.change_tag_bar {
    margin-bottom: 35px;
}

.previewBlockShown {
    z-index: 1;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    span {
	color: #fff;
	margin-left: 10px;
    }
}

.history {
    margin-top: 45px;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 24px;
}

.block {
    flex-direction: column;
}

.item {
    border-bottom: 1px solid $grey200;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.accept {
    color: #63AA58 !important
}

.cancel {
    color: #FF9090 !important;
}

.default {
    color: $black
}

.itemText {
    font-weight: 500;
    font-size: 16px;
    color: #939393;
}

.go_back {
    margin-bottom: 28px;
}

.organization {
    font-weight: 700;
    font-size: 14px;
    color: $blueA600;
    margin-bottom: 24px;
}

.download {
    margin-right: 15px;
    padding: 11px 22px !important;
    height: 44px;

    &:disabled {
	padding: 11px 22px !important;
	max-width: 170px;
    }
}

.controls {
    gap: 8px;

    .unavailableText {
        font-size: 14px;
        color: #C1C1C1;
        margin-right: 12px;
    }
}

// COMMON STYLES

.controls, .item, .block, .preview, .edit_block {
    display: flex;
}

.controls, .preview {
    align-items: center;
}

.edit_block, .item {
    justify-content: space-between;
}

.previewBlockShown, .ref {
    position: absolute;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .pdfLoader {
        margin-left: 8px;
    }

    .constructorLoader {
        margin-left: 10px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
