@import "_btn";
@import "_btn-wrap";

.template-menu {
  position: absolute;
  align-items: flex-start;
  right: 0;
  top: 41px;
  z-index: 1000;
  display: none;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0,0,0,.04), 0 8px 16px rgba(0,0,0,.08);
  min-width: 100px;
  min-height: 100px;
  padding: 8px 0;
  &.show {
    display: flex;
  }
  & svg {
    min-width: 22px;
    margin-left: 16px;
  }
}
