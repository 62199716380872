@import "/src/styles/color";

.wrapper {
    position: relative;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
    max-width: 500px;
}

.notFound {
    margin: 10px 0;
    text-align: center;
    font-size: 19px;
}

.title {
    color: $grey850;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 16px;
}

.modalWrapper {
    padding: 0;
    min-width: 640px;
}

.createWrapper {
    margin-top: 20px;
}

.searchInput {
    width: 100%;
}
