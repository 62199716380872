.wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 30px;

    .tab_item {
        cursor: pointer;
        border-bottom: 2px solid transparent;
        padding-bottom: 8px;
        transition: all 0.2s ease-in-out;
    }

    .active_tab {
        color: #0075FF;
        border-color: #0075FF;
    }
}
