.add_btn {
    border: 1px solid #EDEDED !important;
    border-top: none !important;
    padding: 20px;
}

.multiple_buttons {
    display: flex;

    .add_btn {
	width: 50%;
    }

    .employee_btn {
	border-right: 0 !important;
	border-bottom-right-radius: 0;
    }

    .manager_btn {
	border-bottom-left-radius: 0;
    }
}
