@import "/src/styles/color";

.modal {
    height: 87vh;
    width: fit-content;
    max-width: 600px;
    min-width: 320px;
    position: fixed;
    top: 120px;
    left: 100px;
    z-index: 199;
    display: flex;
    justify-content: center;
    align-items: start;
    font-family: Inter, sans-serif;
    text-align: left;
}

.content_wrapper {
    width: 100%;
    border-radius: 12px;
    background-color: $white;
    position: relative;
    padding: 16px 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 16px 4px 16px;
}

.title {
    margin: 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #272727;
}



.closeBtn {
    cursor: pointer;
}

.tabs {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    color: #9C9C9C;
    margin-bottom: 18px;
    padding: 0 16px;

}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
}

/* CSS TRANSITION*/
.modal_enter {
    opacity: 0;
    transform: scale(1);
}
.modal_enter_active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 400ms, transform 400ms;
}
.modal_exit {
    opacity: 1;
    pointer-events: none;
    transition: all 400ms;
}
.modal_exit_active {
    opacity: 0;
    transition: all 400ms;
}

