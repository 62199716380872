@import "/src/styles/color";

.wrapper {
    padding: 14px 16px;
    color: $grey850;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
	background-color: $background-grey;
    }
}

.active {
    color: $blueA600;
    background-color: $blue90;
}
