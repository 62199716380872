@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
    margin-top: 8px;
}

.inp {
    padding: 24px 24px;
    background: $white;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    min-width: 420px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;
}

.text {
    font-weight: 400;
    font-size: 18px;
    color: $grey850;
    line-height: 1.2;
    margin-right: 10px;
}

.hiddenBlock {
    display: flex;
    align-items: start;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
	width: 6px;
	background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
	background: #99C8FF;
	border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EBEBEB;
    }
}
