@import "../../../base/default_variables";
@import "_graphic-page";
@import "_graphic-text";
@import "_graphic-table";
@import "_size-panel";
@import "_angle-trigger";
@import "_graphic-uniter";
@import "_graphic-module";

.page-frame {
  position: absolute;
  left: var(--left);
  top: var(--top);
  width: var(--width);
  height: var(--height);
  transform: var(--rotate);
  z-index: var(--layer);

  &.hover {
    outline: 1px solid var(--hover-color);
  }

  &.focus {
    outline: 1px solid $color_main_accent !important;

    > .page-frame__size-panel {
      opacity: 1;
    }
    > .page-frame__angle-trigger {
      opacity: 1;
    }
  }

  &.focus-line-horizontal {
    outline: none;
  }
  &.focus-line-horizontal::before {
    content: "";
    position: absolute;
    top: calc(50% - 0.5px);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #0075ff;
    pointer-events: none;
  }

  &.focus-line-vertical {
    outline: none;
  }
  &.focus-line-vertical::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 0.5px);
    width: 1px; /* Толщина линии */
    height: 100%; /* Высота линии */
    background-color: #0075ff;
    pointer-events: none;
  }

  &.editable {
    outline: 1px solid $frame_editable !important;

    > .page-frame__size-panel {
      opacity: 0;
    }
    > .page-frame__angle-trigger {
      opacity: 0;
    }
  }

  &.editable.focus {
    outline: 4px double $color_main_accent !important;
  }

  [contentEditable="true"] {
    outline: none;
  }
}
