:focus {outline:none;}

.slider_wrapper {
    gap: 0;
    overflow: hidden;
    min-height: 30px;
    width: auto;
}

.slider {
    white-space: nowrap;
    overflow-x: auto;
    min-height: 25px;
    min-width: 100px;
    max-width: 100%;
    width: auto;
    overflow-x: hidden;
}

.slider_content {
    display: flex;
    transition: all 0.5s ease;
    gap: 8px;
    top: 0;
}

.left_btn_wrapper {
    padding-right: 37px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 34%);
    left: 0;
}

.right_btn_wrapper {
    padding-left: 37px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 34%);
    right: 0;
}

.hidden_controls {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}


// COMMON STYLES

.slider_wrapper, .slider, .slider_content {
    display: flex;
    transition: all 0.5s ease-in-out;
}

.slider, .slider_wrapper {
    position: relative;
}

.slider_wrapper, .left_btn_wrapper, .right_btn_wrapper {
    align-items: center;
    display: flex;
}

.left_btn_wrapper, .right_btn_wrapper, .slider_content {
    position: absolute;
}

.left_btn_wrapper, .right_btn_wrapper {
    max-height: 100%;
    z-index: 10;
    top: 0;
    display: flex;
    justify-content: center;

    opacity: 1;
    transition: all 0.2s ease-in-out;
}
