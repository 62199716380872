@import "/src/styles/color";

.btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: $blueA600;
    padding: 12px 20px;
    border: 2px solid #CEE4FF;
    border-radius: 32px;
    z-index: 100;
    background-color: $white;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
}

.constructor_btn {
    left: 24px;
    right: inherit;
}
