.trialNotification__link {
	font-family: Inter, sans-serif;
	padding: 0 18px 0 32px;
	color: #0075FF;
	font-size: 16px;
	font-weight: 700;
	line-height: 19.2px;
	text-align: left;
	text-decoration: none;

	&:hover .trialNotification__link {
		  color: #0075FF;
	}
}
