.wrapper {
    padding: 36px 0;
}

.header {
    display: flex;
    margin-top: 20px;
    align-items: start;
    justify-content: space-between;
}

.preview {
    margin-top: 36px;
}
