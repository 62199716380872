.fieldActivity {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: rgba(127, 129, 132, 1);
    margin-bottom: 7px;
}

.selectWrapper {
    display: grid;
    width: 100%;
    margin: 16px 0 34px 0;
}
