@import "_title";
@import "./_fill-input";
@import "./_fill";
@import "./_fill-text";
@import "./_border-color";
@import "./_border-color-input";
@import "./_third-row";
@import "./_border-color-title";
@import "../../base/default_variables";
@import "./_load-xslx";

.table-panel {
	background: #FFFFFF;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 24px;
	margin-top: 8px;
	display: none;
	z-index: 1000;
}
