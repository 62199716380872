@import "/src/styles/color";

.wrapper {
    padding: 24px;
    border: 1px solid $grey200;
    border-radius: 12px;
}

.status_bar {
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}

.main {
    margin-bottom: 16px;
}


.name_info {
    gap: 16px;
    font-weight: 600;
    width: 50%;

    img {
	width: 33px;
	height: 33px;
	border-radius: 50%;
    }

    div {
	color: $black;
	font-size: 17px;
    }
}

.side_info {

    gap: 20px;

    .phone {
	font-size: 14px;
	font-weight: 600;
	color: $black;
    }

    .email {
	font-size: 14px;
	color: #489CEA;
	font-weight: 600;
	cursor: pointer;
    }
}

.status_bar, .main, .name_info, .side_info {
    display: flex;
    align-items: center;
}
