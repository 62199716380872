.wrapper {
    width: 100%;
    max-width: 1300px;
}

.title {
    margin-bottom: 24px;
}

.categories_bar {
    display: flex;
    align-items: start;
    gap: 10px;
    margin: 20px 0;
}
