@import '/src/styles/color';

.wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.item {
    font-weight: 700;
    font-size: 14px;
    color: $blueA600
}
