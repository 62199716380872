@import "../../base/default_colors";

.object-panel__inputs-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px;
	gap: 8px;
	width: 80px;
	height: 32px;
	background: #FFFFFF;
	border: 1px solid $color_stroke_grey;
	border-radius: 8px;
	margin: 0 8px 8px 0;
	position: relative;
	input {
		border: none;
		width: 40px;
		cursor: pointer;
		outline: none;
	}
	svg.svg-fill {
		fill: $color_icon_disabled;
	}
	.svg-stroke {
		stroke: $color_icon_disabled;
	}
	&_active {

	}
	span {
		color: darkred;
		font-size: 10px;
		white-space: nowrap;
		left: 0;
		position: absolute;
		top: -15px;
	}
}

.object-panel__inputs-item:nth-child(4n) span {
	bottom: -15px;
	left: 0;
	top: auto;
}

.object-panel__inputs-item:nth-child(5n) span {
	bottom: -15px;
	left: 0;
	top: auto;
}
