@import "/src/styles/_color.scss";

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 13px 40px;
    background-color: $white;
    border: 1px solid $grey250;
    z-index: 1;

    > .logo_box {
	display: flex;
	align-items: center;
	cursor: pointer;

	> svg {
	    &:nth-child(1) {
		width: 63px;
		height: 47px;
	    }

	    &:nth-child(2) {
		margin-left: 14px;
		margin-bottom: 8px;
		width: 146px;
		height: 30px;
	    }
	}
    }

    > .data_box {
	> .account_box {
	    display: flex;
	    align-items: center;
	    border-radius: 6px;
	    text-decoration: none;
	    cursor: pointer;

	    > .picture {
		border: 2px solid $blueA600;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 16px;
	    }

	    > .name {
		font-weight: 500;
		font-size: 16px;
		color: #000;
		margin-right: 12px;
	    }
	}

	> .block {
	    top: 75px;
	    right: 0;
	}
    }
}
