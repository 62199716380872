@import '/src/styles/color';

.wrapper {
    display: flex;
    align-items: start;
    flex-direction: column;
    max-height: 300px;
    height: 240px;
    overflow-y: auto;
    z-index: 1000;
    position: relative;
    border-radius: 4px;

    &::-webkit-scrollbar {
	width: 6px;
	background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
	background: #99C8FF;
	border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EBEBEB;
    }
}

.not_found {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}
