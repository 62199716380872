@import "/src/styles/color";

.wrapper {
    display: flex;
    align-items: start;
    justify-content: space-between;
    position: relative;
    height: 100%;
    max-height: 100vh;
    padding: 80px;
    overflow: hidden;
}

.logo {
    display: flex;
    align-items: center;
    gap: 18px;
}

.main {
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    font-weight: 600;
    font-size: 80px;
    color: $black;
    line-height: 80px;
    margin-bottom: 30px;
}

.description {
    font-size: 24px;
    color: $black;
    font-weight: 400;
    margin-bottom: 35px;
    line-height: 33px;
}

.background_picture {
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    max-width: 100%;
}

.picture {
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
    max-width: 100%;

}

@media (max-width: 1700px) {
    .picture {
	top: 100px;
    }

    .background_picture {
	width: 50%;
    }
}

@media (max-width: 1400px) {
    .picture {
	top: 200px;
	right: -50px;
    }

    .background_picture {
	width: 40%;
    }
}
