@import "../../../base/default_variables";

.picture-block__trim-area {
  position: absolute;
  left: -1px;
  top: -1px;
  border: 1px solid $color_main_accent;
  width: calc(100% + 2px);
  height: calc(100% + 2px);

  > {
    .left-top {
      position: absolute;
      top: -4px;
      left: -4px;
      width: 13px;
      height: 13px;
      border-left: 7px solid #0075FF;
      border-top: 7px solid #0075FF;
    }

    .left-middle {
      position: absolute;
      left: -4px;
      top: 50%;
      transform: translateY(-50%);
      height: 22px;
      border-left: 7px solid #0075FF;
    }

    .left-bottom {
      position: absolute;
      bottom: -4px;
      left: -4px;
      width: 13px;
      height: 13px;
      border-left: 7px solid #0075FF;
      border-bottom: 7px solid #0075FF;
    }

    .right-top {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 13px;
      height: 13px;
      border-right: 7px solid #0075FF;
      border-top: 7px solid #0075FF;
    }

    .right-middle {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      height: 22px;
      border-right: 7px solid #0075FF;
    }

    .right-bottom {
      position: absolute;
      bottom: -4px;
      right: -4px;
      width: 13px;
      height: 13px;
      border-right: 7px solid #0075FF;
      border-bottom: 7px solid #0075FF;
    }
  }

  &.hide {
    display: none;
  }
}
