@import "../../styles/color";

.wrapper {
    position: relative;
    display: inline-block;
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    position: relative;
    width: 400px;
}

.choicedWrapper {
    padding: 8px 18px;
    background: $white;
    border: 1px solid #EDEDED;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
    min-height: 49px;

    .text {
	font-weight: 400;
	font-size: 14px;
	line-height: 170%;
	color: $grey850;
    }
}

.open {
    border-radius: 20px 20px 0 0;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.search {
    min-width: 400px;
}

.hiddenBlock {
    width: 400px;
    position: absolute;
    background-color: $white;
    display: flex;
    align-items: start;
    flex-direction: column;
    padding-bottom: 10px;
    border: 1px solid #EDEDED;
    top: 48px;
    border-radius: 0 0 20px 20px;
    overflow-y: auto;
    z-index: 1000;

    &::-webkit-scrollbar {
	width: 6px;
	background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
	background: #99C8FF;
	border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EBEBEB;
    }
}

.role {
    margin: 0 !important;
}

.roleWrapper {
    width: 100%;
    cursor: pointer;
    padding: 0 10px;
}

.teamWrapper {
    width: 100%;
    font-size: 16px !important;
    padding: 8px 20px !important;
}

.roleScroll {
    margin: 0 !important;
    margin-top: 10px !important;
}
