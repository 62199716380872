@import "/src/styles/color";

.search_block {
    display: flex;
    align-items: center;
    position: relative;

    .input {
	padding: 5px;
	font-size: 14px;
	border: none;
	border-bottom: 1px solid $blueA600;
	outline: none;
	margin-right: 20px;
    }

    .find_icon {
	position: absolute;
	right: 0;
	cursor: pointer;

	path {
	    transition: all 200ms ease;
	}

	&:hover {
	    path {
		stroke: $blueA600;
	    }
	}
    }

    .active_icon {
	svg path {
	    stroke: $blueA600;
	}
    }
}
