@import "../../../base/default_variables";

$width: 606.6141732283464px;

.page-frame__graphic-page {
  position: relative;
  margin: 30px 0 40px;
  background: var(--background);
  background-image: var(--backgroundImage);

  &.portrait {
    width: 210mm;
    height: 297mm;
  }

  &.landscape {
    width: 297mm;
    height: 210mm;
  }

  &.focus {

  }
}
