@import "/src/styles/color";

.header {
    padding: 16px 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo_box {
    display: flex;
    align-items: center;
    cursor: pointer;

    > svg {
	&:nth-child(1) {
	    width: 63px;
	    height: 47px;
	}

	&:nth-child(2) {
	    margin-left: 14px;
	    margin-bottom: 8px;
	    width: 146px;
	    height: 30px;
	}
    }
}

.wrapper {
    display: flex;
    align-items: center;
}

.enter {
    margin-right: 10px;
    width: 90px;
    height: 48px;
    padding: 11px 22px 13px 22px;
}

.reg {
    width: 180px;
    height: 48px;
    padding: 11px 22px 13px 22px;
}

.name {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-decoration: none;
    color: $black;
}
