@media (max-width: 1500px) {
	.page-container_container {
		margin-right: 5%;
	}
}

@media (max-width: 1420px) {
	.page-container_container {
		margin-right: 10%;
	}
}

@media (max-width: 1350px) {
	.page-container_container {
		margin-right: 15%;
	}
}

@media (max-width: 1300px) {
	.page-container_container {
		margin-right: 20%;
	}
}

@media (max-width: 1235px) {
	%tpl_Tool {
		width: 45px;
		height: 45px;
		padding: 5px;
	}
	.page-properties, .object-panel, .text-panel  {
		padding: 12px;
		width: 250px;
	}
	.propertyBar {
		width: 250px;
	}
	.sketch-manipulator__pages-container {
		padding-top: 100px;
	}
	.page-frame__graphic-page.portrait {
		//transform: scale(0.8);
	}
	.page-container_container {
		margin-right: 18%;
	}
	.top-bar {
		height: 35px;
	}
	.page-header {
		width: 73%;
	}
	.page-frame__graphic-page {
		margin: 23px 30px 40px;
	}
	.propertyBar {
		right: 10px;
	}
	.toolbar {
		left: 10px;
	}
	.top-bar {
		top: 10px;
		right: 10px;
		left: 10px;
		height: 60px;
	}
	.page-properties__title, .object-panel__title, .text-panel__title {
		margin: 0 0 10px 0;
		font-size: 13px;
	}
	.object-panel__buttons-item:nth-child(5n) {
		margin-right: 0;
	}
	.object-panel__buttons-item:nth-child(7n) {
		margin: 0 8px 16px 0;
	}
}

@media (max-width: 1024px) {
	.table-constructor__values {
		height: 10px;
		width: 50px;
	}
	.sketch-manipulator .svg {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.table-constructor__set-values, .table-constructor__load-excel {
		gap: 15px;
		display: grid;
		grid-template-columns: 20px auto;
	}
	.panel-modules > div,
	.panel-components > div,
	.panel-action > div {
		width: 24px;
		margin: 0 0 10px;
		height: 24px;

	}
	.panel-components div:last-child,
	.panel-modules div:last-child {
		margin: 0;
	}

	.panel-components,
	.panel-action,
	.panel-modules,
	.panel-zoom {
		padding: 9px;
	}
	.panel-action > div, .panel-modules__modules, .panel-lists__order {
		padding: 3px;
	}
	.text-panel__item,
	.page-properties__item {
		width: 22px;
		height: 22px;
	}
	.top-bar {
		height: 35px;
	}

	.figure-panel__fill-text,
	.figure-panel__border-text,
	.figure-panel__radius-text {
		margin: 0 8px 0 0;
	}

	.figure-panel__border-width-input,
	.t1001-select__input-wrapper,
	.figure-panel__radius-input {
		height: 25px;
	}

	.figure-panel__border-text,
	.figure-panel__fill-text,
	.figure-panel__radius-text,
	.template-menu__btn {
		font-size: 13px;
	}
	.template-menu {
		top: 41px;
	}

	.img-panel__buttons-wrap button {
		font-weight: 600;
		font-size: 12px;
		padding: 5px 10px;
	}
	.top-bar__back-btn {
		height: 15px;
		width: 15px;
	}

	.text-panel__font {
		width: 110px;
		height: 28px;
	}
	.text-panel__size {
		width: 30px;
		height: 28px;

	}
	.text-panel__font-style {
		padding: 2px;
	}
	.page-properties__item, .color-input_container.page-properties__item {
		margin-right: 13px;
	}
	.page-properties__background-image {
		margin-left: 0;
	}
	.text-panel__list .text-panel__item.text-panel__color {
		display: inline-flex;
		height: 30px;
	}
	.text-panel__list .text-panel__item.text-panel__color .color-input_container {
		margin-right: 0;
	}
}

@media (max-width: 980px) {
	.reportButton_btn__yqN3R {
		display: none;
	}
	%tpl_Tool {
		width: 30px;
		height: 30px;
		padding: 5px;
	}
	.page-frame__graphic-page.portrait {
		//transform: scale(0.7);
	}
	.page-header {
		width: 61%;
	}
	.top-bar {
		height: 40px;
	}
	.top-bar__back-btn {
		height: 15px;
	}
	.top-bar__back-btn svg {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.template-name__text {
		font-size: 14px;
	}
	.img-panel__buttons-wrap button, .table-panel__load-xslx {
		font-size: 12px;
		line-height: 110%;
		padding: 3px 14px;
	}
	.img-panel__crop-btn {
		margin-left: 8px;
	}
	.img-panel__crop-btn svg {
		width: 17px;
	}
	.top-bar__modes-wrapper {
		height: 38px;
	}
}

@media (max-width: 920px) {
	//.page-properties__item,
	//.object-panel__buttons-item,
	//.text-panel__item {
	//	height: 20px;
	//	width: 20px;
	//}
	//.page-properties__item svg,
	//.object-panel__buttons-item svg,
	//.text-panel__item svg {
	//	width: 100%;
	//	height: 100%;
	//	object-fit: contain;
	//}
	//.propertyBar {
	//	top: 60px;
	//}
	//.page-properties, .object-panel, .text-panel {
	//	padding: 8px;
	//}
	//.color-input_color {
	//	width: 18px;
	//	height: 18px;
	//}
	//.object-panel__inputs-item {
	//	width: 60px;
	//	height: 24px;
	//
	//}
	//.page-properties, .object-panel, .text-panel {
	//	width: 222px;
	//}
	//.sketch-manipulator__pages-container {
	//	padding-top: 85px;
	//}
	//.propertyBar {
	//	right: 10px;
	//}
	//.toolbar {
	//	transform: scale(0.7);
	//	top: 25px;
	//
	//}
	//.page-properties__title, .object-panel__title, .text-panel__title {
	//	margin: 0 0 5px 0;
	//	font-size: 11px;
	//}
}


@media (max-width: 780px) {
	.page-frame__graphic-page.portrait {
		//transform: scale(0.5);
	}
}

@media (max-height: 768px) {

}

@media print {
	body {
		overflow: initial !important;
	}

	.sketch-manipulator__pages-container {
		display: flex;
		align-items: normal;
		flex-direction: column;
		padding-top: 0;
		overflow: hidden;
	}

	.page-frame__graphic-page {
		margin: 0;
		overflow: hidden;
	}

	.page-frame__graphic-page.portrait {
		transform: none;
	}

	.page-container_container {
		margin: 0;
	}

	.page-frame__size-panel {
		display: none;
	}

	header {
		display: none;
	}

	.left-side-bar {
		display: none !important;
	}

	[class*="commercialPanel_wrapper"] {
		display: none !important;
	}

	.Toastify {
		display: none;
	}
}
