@import '/src/styles/color';

.wrapper {
    cursor: pointer;
    position: relative;

    svg {
	position: absolute;
	right: 50%;
	top: 50%;
	transform: translate(50%, -50%);
    }
}

.big {
    padding: 13px 16px;
    height: 39px;
    width: 39px;
}

.small {
    padding: 11px 12px;
    width: 32px;
    height: 32px;
}
