.panel-ai svg {
	stroke: none;
}
.panel-ai:hover svg path {
	fill: #0075ff;

}

.panel-ai > div {
	background: url('../../../interface/bars/tool-bar/assets/gif/ai.gif') no-repeat center center / 60% 60%;
}

.panel-ai:hover > div {
	background: #d6e9ff url('../../../interface/bars/tool-bar/assets/gif/ai.gif') no-repeat center center / 60% 60%;
}

