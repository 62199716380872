.panel {
	background-color: transparent;
}
.button {
	width: 100%;
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	text-align: left;
	font-size: 16px;
	&:hover {
		background-color: #0056b3;
	}
}
.openedList{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: fit-content;
	margin-bottom: 20px;
}
.openedListBtn {
	font-weight: 400;
	font-size: 14px;
	color: #939393;
	margin-right: 8px;
	display: block;
	cursor: pointer;
	font-family: Inter, sans-serif;
	line-height: 18.2px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;

}
.rotate {
	transform: rotateX(180deg);
}
.arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 8px;
	height: 4px;

	svg {
		path {
			stroke: #939393;
		}
	}
}
