.color-input_container {
  position: relative;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  width: 30px;
  height: 30px;
  transition: all .1s ease;
  margin-right: 6px;

  &:hover {
    cursor: pointer;
    border: 1px solid #EEEEEE;
  }

  &:active {
    cursor: pointer;
    border: 1px solid #A6A6A6;
  }

  .active {
    border: 1px solid #A6A6A6;
  }
}

.color-input_color {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 22px;
  height: 22px;
  border: 1px solid #A6A6A6;
  border-radius: 3px;
}
