@import "/src/styles/color";

.wrapper {
    width: 100%;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    min-width: 360px;
    padding: 26px 24px;
    border-radius: 8px;
    align-items: center;
    border: 1px solid $light-grey;
    justify-content: space-between;

    svg {
	width: 12px;
	height: 6px;
    }
}
