.picture-block__pending-plus {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .2s opacity ease-in-out;

  &.show {
    cursor: pointer;
    opacity: 1;
  }
}
