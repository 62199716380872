.text-panel__options {
	position: absolute;
	border-radius: 4px;
	text-align: center;
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 1);
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 340px;
	display: none;
	z-index: 1000;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 0 4px 4px 0;
	}

	& .text-options__item {
		padding: 8px 16px;
		font-family: 'Inter', sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0;
	}
	& .text-options__item:hover {
		background-color: rgb(224, 222, 222);
	}

	& .text-options__item.active {
		background-color: rgb(238, 231, 231);
	}
}
