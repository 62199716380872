@import "/src/styles/color";

.btn {
    text-align: center;
    border: none;
    border-top: 1px solid #EDEDED;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 8px 8px;
    background-color: transparent;
    width: 100%;
    outline: none;

    .text {
	color: $blueA600;
	font-weight: 700;
	font-size: 14px;
	margin-left: 8px;
    }
}
