@import "/src/styles/color";

.loading {
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 2px solid $white;
    border-radius: 50%;
    margin: 0 auto;
}
