@import "/src/styles/color";

.title_block {
    display: flex;
    align-items: center;
    margin: 26px 0 13px 0;

    .title {
	font-size: 23px;
	font-weight: 500;
	color: $grey850;
	margin: 0 10px 0 0;
    }

    .icon {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
    }
}
