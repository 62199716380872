.page-frame__graphic-text {

  // Нужно для работы выравнивая текста
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 4px 3px 4px 4px;
  width: 100%;
  min-width: 20px;
  word-break: break-word;

  &.auto-width {
    width: max-content;
    padding: 4px;
  }
}
