.empty_wrapper, .wrapper, .skeleton_wrapper {
    margin-top: 30px;
    flex: 1;
}

.empty_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
