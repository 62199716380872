@import '/src/styles/color';

.wrapper {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 8px;
}

.value {
    color: $black
}

.description {
    color: $grey;
    font-size: 13px;
}

.value, .description {
    font-weight: 400;
}
