.table-constructor__values {
	text-align: center;
	margin-bottom: 16px;
	align-items: center;
	display: flex;
	justify-content: center;
	span {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #272727;
		margin-right: 8px;
	}
	span:nth-child(2) {
		margin-right: 0;
	}
	svg {
		margin-right: 8px;
	}
}