@import "/src/styles/color";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    font-family: "Inter", sans-serif;
    flex-grow: 1;
}

.wrapper {
    display: block;
    padding: 37px 73px;
    border: 1px solid $grey200;
    border-radius: 12px;
    min-width: 450px;
}

.title {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 35px;
    color: $grey850;
    margin: 0;
    text-align: center;
}

.descr {
    font-weight: 500;
    color: #939393;
    text-align: center;
    margin-top: 15px;
}

.block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 39px auto 0;
    width: 426px;
}

.underInput {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.rememberWrapper {
    display: flex;
    cursor: pointer;
}

.remember {
    color: #939393;
    margin-left: 10px;
}

.forgetPassword {
    color: $blueA600;
    cursor: pointer;
}

.button, .enter_button {
    width: 100%;
}

.enter_button {
    margin: 32px auto 0;
}

.underButton {
    margin: 18px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    max-width: 290px;
}

.noAccount {
    color: #939393;
}

.registration {
    color: $blueA600;
    cursor: pointer;
}

.rememberPassword {
    font-weight: 400;
    font-size: 14px;
    color: $blueA600;
    margin: 32px auto 0;
    cursor: pointer;
}

.error {
    color: #E24360;
    width: 100%;
    display: flex;
    align-items: center;
    transition: all 3s linear;
    margin-bottom: 24px;

    div {
	font-weight: 400;
	font-size: 14px;
	color: #939393;
	margin-left: 10px;
    }
}
