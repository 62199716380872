@import "/src/styles/color";

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-top: 28px;
}

.skeleton_loading_templates {
    height: 250px;
    width: 250px;
    border-radius: 16px;
    border: 1px solid $grey250;
    animation: skeleton-loading 1s linear infinite alternate;
    background-color: #F3F3F9;
}

@keyframes skeleton-loading {
    0% {
	background-color: #E9ECF6;
    }

    100% {
	background-color: hsl(200, 6%, 90%);
    }
}
