@import "/src/styles/color";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 35px;
    color: $grey850;
    margin: 0;
    text-align: center;
}

.descr {
    font-weight: 500;
    color: $grey650;
    text-align: center;
    margin-top: 15px;

    .black {
	color: $grey850;
    }
}

.picture {
    display: flex;
    justify-content: center;
}
