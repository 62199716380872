.tags_block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 10px;
    min-height: 25px;

    .plus {
	cursor: pointer;
    }
}

.tag {
    margin: 0 !important;
}
