.template-menu__btn-wrap {
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background: #eaedf1;
    transition: all .2s ease;
  }
}
