.mext-editor {
    white-space: break-spaces;
}

.mext-b {
    font-weight: bold;
}

.mext-i {
    font-style: italic;
}

.mext-df {
    animation: flow 15s ease-in-out infinite;
    background: linear-gradient(-60deg, #904e95, #904e95, #e73c7e, #ee7752);
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
@keyframes flow {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
