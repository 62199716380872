.figure-panel__border-color-input {
	background: #FFFFFF;
	border: 1px solid #EEEEEE;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	cursor: pointer;
	position: relative;
	padding: 0;
	margin-right: 24px;
	&:after {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		background: #FFFFFF;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 2px;
	}

}

.figure-panel__border-color-input::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 4px;
	padding: 3px;
}

.figure-panel__border-color-input::-webkit-color-swatch {
	border: none;
	border-radius: 4px;
	padding: 3px;
}