.table-input-modal__input-rows {
	margin-right: 15px;
	max-width: 160px;
	padding: 16px 24px;
	border: 1px solid #EDEDED;
	border-radius: 8px;
	margin-bottom: 24px;
	input {
		border: none;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 130%;
		color: #939393;
		margin-bottom: 8px;
		max-width: 100%;

	}
	input:focus {
		color: #000;
	}
	h5 {
		margin: 0;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 130%;
		color: #939393;
	}

}