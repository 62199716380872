@import "/src/styles/color";

.button {
    padding: 13px 22px;
    color: $blueA600;
    border: 2px solid $blue90;
    max-width: 235px;
    position: relative;
    transition: all .2s ease-in-out;

    &:hover {
	transition: all .2s ease-in-out;
	background-color: $blue90;
    }

    &:disabled,
    button[disabled] {
	background: #CEE4FF;
    }
}

.active {
    background-color: transparent;
}

.text {
    margin-left: 10px;
}

.long {
    min-width: 235px;
}

.process {
    height: 52px;
    width: 148.67px;
}
