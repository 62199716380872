@import "./_back";
@import "_forward";

.panel-action {
    background: #FFFFFF;
    border-radius: $borderRadiusPanelElement;
    padding: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
    margin-bottom: 8px;
    & > div {
        @extend %tpl_Tool;
        stroke: #CDCDCD;
        display: flex;
        justify-content: center;
        margin: 0 !important;
        &:hover {
            stroke: $panelElementHover;
        }
        &:active {
            stroke: $actionColorOnClick !important;
        }
    }
}
