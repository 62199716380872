@import "../../../../../styles/color";

.wrapper {
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  color: $black;
  background-color: $background-grey;
  display: inline-block;
  border-radius: 60px;
  white-space: nowrap;
}
