.title, .main_title {
    color: #939393;
    font-weight: 400;
    font-size: 14px;
    max-width: 50px;
    flex-wrap: wrap;
}

.main_title {
    width: 65%;
    text-align: left;
    padding: 16px 24px;
}
