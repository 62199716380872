.table-constructor__set-values {
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
	color: #0075FF;
	display: flex;
	align-items: center;
	width: fit-content;
	margin-bottom: 19px;
	&:hover {
		span{
			color: #4E9FFF;
		}
		svg {
			fill: #4E9FFF;
		}
	}
	svg {
		fill: #0075FF;
		margin-right: 10px;
	}
	span {
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
	}
}