.keyWordsLabel {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: rgba(127, 129, 132, 1);
    margin-bottom: 18px;
}

.selectWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin-bottom: 34px;
}
.responsesWrapper {
    max-height: 310px !important;
}
.symbolsCount {
    position: absolute;
    right: 30px;
}
