.wrapper {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin: 16px 0 24px 0;
}

.search {
    min-width: 350px;
}
