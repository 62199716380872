@import "../../../../../../../../../../styles/color";

.wrapper {
  position: relative;
  padding-top: 40px;
  font-family: 'Inter', sans-serif;
  max-width: 420px;
}

.title {
  color: $grey850;
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 16px;
}

.description {
  margin-bottom: 20px;
}

.buttonBlock {
  width: 100%;
  display: flex;
  align-items: center;
}

.button {
  margin-top: 24px;
}
