.dropdown {
    max-height: 410px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
        background: #99C8FF;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #EBEBEB;
    }
}

.btn {
    border-top: none;
}
