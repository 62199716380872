@import "../../../base/default_variables";

.page-frame__graphic-union {
  width: 100%;
  height: 100%;
  border: 1px solid $frame_focus;

  &.focus {
    border: 1px solid $frame_focus;
  }
}
