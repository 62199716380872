@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
    padding: 8px;
    background-color: $white;
    border-radius: 8px;
}

.tagsBlock {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    min-width: 247px;

    &::-webkit-scrollbar {
	width: 6px;
	background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
	background: #99C8FF;
	border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EBEBEB;
    }
}

.input {
    div {
	padding: 5px 0 5px 15px !important;
    }

    margin-bottom: 8px;
}

.tag {
    font-weight: 500;
    font-size: 13px;
    color: $grey850;
    background: $background-grey;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.createBtn {
    margin: 8px 0;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blueA600;
    cursor: pointer;

    span {
	display: block;
    }
}

.description {
    font-weight: 400;
    font-size: 13px;
    color: #939393;
    margin-bottom: 8px;
    text-align: center;
}
