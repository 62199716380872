@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
    padding: 8px 0;
    display: flex;
    align-items: center;
}

.checkbox {
    margin-right: 8px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: $white;
}

.text {
    font-weight: 400;
    font-size: 14px;
    color: $grey850;
}
