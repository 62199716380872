@import "./_crop-btn";
@import "./_apply-btn";
@import "./_cancell-btn";
@import "./_buttons-wrap";

.img-panel {
	display: none;
	grid-template-columns: [crop-tool] auto [btns-wrap] 260px;
	align-items: center;
	width: 100%;

}
