@import "/src/styles/color";

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 19px;
    position: relative;

    svg {
	&:first-child {
	    margin-right: 7px;
	}
    }
}

.backBtn {
    display: flex;
    align-items: center;
    color: $blueA600;
    position: absolute;
    left: 0;
    cursor: pointer;
}
