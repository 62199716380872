@import "../../../base/default_variables";

.page-frame__size-panel {
  display: flex;
  align-items: center;
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
  color: $color_text_white;
  background: $color_main_accent;
  padding: 2px 8px 2px 8px;
  font-size: 12px;
  margin-top: 4px;
  border-radius: 4px;
  font-weight: 200;
  pointer-events: none;
  //opacity: 0;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: calc(50% - 4px);
    border-right: 4px solid transparent;
    border-bottom: 4px solid $color_main_accent;
    border-left: 4px solid transparent;
  }

  > p {
    margin: 0;
  }

  > span {
    position: relative;
    top: -1px;
    padding: 0 2px;
    font-size: 10px;
    font-weight: 200;
  }
}
