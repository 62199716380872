.text-panel__link {
	width: auto;
	padding: 6px 8px;
	svg {
		margin-right: 9px;
	}
	h5 {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 130%;
		color: #7F8184;
	}
}
