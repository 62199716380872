.selectWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 34px;
}

.content {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: rgba(127, 129, 132, 1);
    margin-bottom: 24px;
}
