@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
    padding: 5px 10px;
    background: $background-grey;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease;
    position: relative;
}

.text {
    font-weight: 500;
    font-size: 15px;
    color: $grey900;
}

.active {
    background-color: #CDE4FF;
}
