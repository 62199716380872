.advantList {
	display: flex;
	flex-direction: column;
	font-family: Inter, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 34px;
	text-align: left;
	color: rgba(127, 129, 132, 1);
	margin-bottom: 7px;
}
.selectWrapper {
	display: grid;
	margin: 12px 0 34px 0;
}
.responsesWrapper {
	max-height: 340px !important;
}
