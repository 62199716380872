@import "../../base/default_colors";
@import "_title";
@import "_inputs-item";
@import "_inputs-list";
@import "_buttons-list";
@import "_buttons-item";
@import "_square-button";
@import "__error/x-coordinates";
@import "__error/_error";
@import "_corner-radius";
@import "_corner-radius-input";
@import "_boom";
@import "_label";
@import "_rotate-img-load";
@import "_corner_radius-options";

.object-panel {
	background: #FFFFFF;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 24px;
	margin-top: 8px;
	display: none;
	z-index: 1000;
}
