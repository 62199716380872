@import '/src/styles/color';

.wrapper {
    max-width: 200px;
    position: relative;
}

.main {
    gap: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;

    svg {
        margin-top: 1px;
    }
}

.dropdown {
    margin-top: 15px;
    max-height: 250px;
    overflow-y: auto;
}

.dropdown::-webkit-scrollbar {
    width: 4px;
}

.dropdown::-webkit-scrollbar-track {
    background: $grey200;
    border-radius: 8px;
}

.dropdown::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 3px solid $grey850;
}
