@import "/src/styles/color";

.wrapper {
    width: 100%;
    position: relative;
}

.field {
    padding: 25px 34px;
    background-color: $white;
    border: 1px solid $grey370;
    border-radius: 6px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: $black;
    outline: none;
    width: 100%;
    margin-bottom: 15px;
    transition: all 0.1s ease;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.success {
    border: 1px solid #63AA58 !important;
}

.error {
    border: 1px solid #FF3636 !important
}

.focused {
    border: 1px solid $blueA600;
}

.emoji {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;

    .bg {
	background: $background-grey;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
    }
}

.emojiInput {
    padding-left: 60px;
    padding-right: 50px;
}

.inp {
    border: none;
    outline: none;
    position: absolute;
    left: 57px;
    top: 23px;
    border-radius: 6px;
    padding: 3px 11px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: $black;
    max-width: 230px;
    margin-left: 3px;
}

.smile {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
