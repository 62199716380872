.wrapper {
    margin: 16px 0 16px 0;

    .filtration {
		display: grid;
		grid-template-columns: 1fr 500px;
	    gap: 15px;
        margin-bottom: 24px;
    }

    .search {
        width: 100%;
        min-height: 54px;
        margin-left: auto;
    }
}
