.title {
    font-size: 16px;
    font-weight: 500;
}

.controls {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-top: 8px;
}
