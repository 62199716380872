@import "color";

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all .4s ease-in-out;
  z-index: 2;

  > .window {
    background: $white;
    padding: 20px 26px;
    border: 1px solid $grey200;
    border-radius: 12px;
    min-width: 340px;

    > h3 {
      margin: 12px 0;
      font-weight: 500;
      font-size: 23px;
      color: $grey800;
      user-select: none;
    }

    > .close {
      display: flex;
      align-items: center;
      justify-content: right;

      > svg {
        padding: 4px;
        fill: $grey370;
        transition: all .2s ease-in-out;

        &:hover {
          cursor: pointer;
          fill: $grey750;
        }
      }
    }

    > .body {
      > .confirm {
        > p {
          max-width: 300px;
        }
        > .button-box {
          > .positive {
            margin-left: 20px;
          }
        }
      }

      > .button-container {
        display: flex;
        align-items: center;

        > .right {
          margin-left: auto;
        }
      }

      .delete-dynamic-field {
        display: flex;
        align-items: center;
        font-size: .96em;
        color: $grey850;

        &:hover {
          cursor: pointer;

          svg {
            fill: $grey750;
          }
        }

        > svg {
          fill: $grey350;
          transition: all .2s ease-in-out;
          padding: 4px;
        }
      }
    }
  }
}

.structure-preview-pages {
  > .page {
    position: relative;
    margin: 20px 0;
    box-shadow: 0 6px 89px 6px #0000000D;

    &.portrait {
      width: 210mm;
      height: 297mm;
    }
    &.landscape {
      width: 297mm;
      height: 210mm;
    }

    &.complete-offer {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;

      > h1 {
        color: #212121;
        text-align: center;
      }
    }

    &:first-child {
      margin-top: 40px;
    }

    &:last-child {
      margin-bottom: 40px;
    }
  }
}