@import "/src/styles/color";

.wrapper {
    font-weight: 700;
    font-size: 16px;
    color: $blueA600;
    border: none;
    cursor: pointer;
    background-color: transparent;
}
