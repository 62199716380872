@import "src/components/Sketch/styles/base/default_variables";

.error-layers-indicator {
  display: none;
  align-items: center;
  font-size: .9em;
  padding: 6px 10px;
  border-radius: 8px;
  margin-left: 16px;
  background: $color_status_error_hover;
  color: $color_text_white;
  opacity: .6;

  &.show {
    display: flex;
  }

  > * {
    padding: 0;
    margin: 0;
  }

  > span {
    padding: 2px 5px;
    margin-left: 8px;
    border-radius: 100%;
    background: $color_text_white;
    color: $color_status_error_hover;
  }
}
