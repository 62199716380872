@import '/src/styles/color';

.wrapper {
    gap: 8px;
    cursor: pointer;
    padding: 8px 16px;
    width: 100%;


    &:hover {
	background: $background-grey;

	.text {
	    color: $blueA600;
	}

	path {
	    stroke: $blueA600;
	}
    }
}

.text {
    font-size: 14px;
    font-weight: 400;
}

.icon {
    justify-content: center;
}

// COMMON

.wrapper, .icon {
    display: flex;
    align-items: center;
}

.wrapper, .text, .icon, path {
    transition: all 0.2s ease-in-out;
}
