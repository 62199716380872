.role {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    margin-right: 8px;
    border-radius: 4px;
    margin-top: 10px;
    position: relative;
    //border: 2px solid transparent;

    &:hover {
        opacity: 0.9;
        transition: 0.2s ease;
    }
}

.text {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #343434;
    margin-left: 5px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        margin: 0 auto;
    }
}

.delete {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #D9D9D9;
    width: 17px;
    height: 17px;
    border-radius: 50%;
}

.delete:before,
.delete:after {
    content: "";
    position: absolute;
    width: 11px;
    height: 1px;
    background: gray;
    top: 8px;
    right: 3px;
}

.delete:before {
    transform: rotate(45deg);
}

.delete:after {
    transform: rotate(-45deg);
}

.exampleEnter {
    opacity: 0;
    transform: scale(0.9);
}
.exampleEnterActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
}
.exampleExit {
    opacity: 1;
}
.exampleExitActive {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 200ms, transform 200ms;
}
