@import "/src/styles/color";

.wrapper {
    position: relative;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
    max-width: 500px;
}

.notFound {
    margin: 10px 0;
    text-align: center;
    font-size: 19px;
}

.title {
    color: $grey850;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 16px;
}

.roleCreateBtn {
    max-width: 155px;
    width: 155px;
    height: 44px;
    font-size: 14px;

    &:disabled,
    button[disabled] {
	padding: 11px 22px;
    }
}

.userCreateBtn {
    max-width: 230px;
    width: 230px;
    max-height: 44px;
    font-size: 14px;
    height: 44px;

    &:disabled,
    button[disabled] {
	padding: 11px 22px;
    }
}

.tooltip,
.roleTooltip,
.colorTooltip {
    position: absolute;
    background-color: #5A5A5A;
    color: $white;
    padding: 13px 18px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;

    &::after {
	content: '';
	position: absolute;
	border: 10px solid transparent;
	border-left: 10px solid #5A5A5A;
	transform: rotate(-90deg);
    }
}

.roleTooltip {
    bottom: -15px;
    left: -30px;

    &::after {
	right: 66px;
	top: -17px;
    }
}

.colorTooltip {
    bottom: -15px;
    left: 200px;

    &::after {
	right: 23px;
	top: -17px;
    }
}

.modalWrapper {
    padding: 0;
    min-width: 640px;
}

.roleBlock {
    margin-top: 24px;
    margin-bottom: 24px;
}

.rolesAddBtn {
    padding: 10px 24px;
    font-size: 14px;
    height: 38px;
    width: 117px;

    &:disabled,
    button[disabled] {
	padding: 8px 24px;
    }
}

.createWrapper {
    margin-top: 20px;
}

.searchInput {
    width: 100%;
}
