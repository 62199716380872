.page-properties__repeating-image-size-input {
	background: #FFFFFF;
	border: 1px solid #EEEEEE;
	border-radius: 8px;
	width: 64px;
	height: 32px;
	cursor: pointer;
	position: relative;
	padding: 8px;
	margin-left: 12px;
	&:after {
		content: '';
		position: absolute;
		width: 21px;
		height: 21px;
		background: #656565;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 4px;
	}
}

.page-properties__repeating-image-size-field {
	display: none;

	&.show {
		display: block;
	}
}

.page-properties__repeating-image-size-input::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 8px;
	padding: 3px;
}

.page-properties__repeating-image-size-input::-webkit-color-swatch {
	border: none;
	border-radius: 8px;
	padding: 3px;
}
