@import '/src/styles/color';

.wrapper {
    width: 340px;
    overflow: hidden;
    border-radius: 16px;
    border: 1px solid $light-grey;
    background-color: $white;
}

.opened_wrapper {
    border-radius: 16px 16px 0 0;
}

.scroll_content {
    border-radius: 0;
}

.default {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
}

.content {
    width: 340px;
    position: absolute;
    border: 1px solid $light-grey;
    z-index: 11;
    border-radius: 0 0 16px 16px;
    background-color: $white;
    overflow: hidden;
}
