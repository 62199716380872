@import "_link";
@import "_text";

.trialNotification {
	font-family: Inter, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 20.8px;
	text-align: center;
	display: flex;
  justify-content: end;
}
