.wrapper {
    max-width: 1300px;
    width: 100%;
}

.title {
    margin-bottom: 24px;
}

.btn {
    margin-top: 24px;
}
