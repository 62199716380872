@import "/src/styles/color";

.wrapper, .content {
    display: flex;
    align-items: start;
    flex-direction: column;
}

.wrapper {
    gap: 16px;
    position: relative;
}

.content {
    gap: 8px;
}

.title {
    margin-bottom: 8px;
    font-size: 23px;
    font-weight: 500;
    color: $black;
}

.calendar {
    position: absolute;
    top: -15px;
    right: -220px;
    width: 400px;
}