@import "/src/styles/color";

.tags_wrapper {
    padding: 15px 24px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
}

.clean_block {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    div {
	color: $blueA600;
	margin-left: 7px;
    }
}

.not_found {
    text-align: center;
}
