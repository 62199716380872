@import "/src/styles/color";

.wrapper {
    padding: 0 20px 20px 20px;
    margin-top: 20px;
    height: 300px;
    overflow-y: scroll;
    min-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::-webkit-scrollbar {
	width: 6px;
	background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
	background: #99C8FF;
	border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EBEBEB;
    }
}

.item_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1100px;

    .item {
	margin-right: 10px;
	border-radius: 6px;
	border: 2px solid transparent;
	margin-bottom: 5px;
	transition: all 0.2s ease;
	cursor: pointer;
    }
}

.btn {
    max-width: 200px;
    display: block;
    padding: 10px;
}

.not_found {
    border: none;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
