.wrapper {
    display: flex;

    div {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 7px;
    }

    .unbind {
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	background-color: #EAEDF1;
    }
}
