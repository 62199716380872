.table-input-modal__btn {
	background: #0075FF;
	border-radius: 32px;
	padding: 12px 24px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	color: #FFFFFF;
	&:hover {
		background: #4E9FFF;
	}
}