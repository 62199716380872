@use "/src/styles/color";

.wrapper {
    padding: 4px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.hint_wrapper {
    position: relative;
}

.close_icon {
    margin-left: 6px;
}

.active {
    cursor: pointer;
    color: color.$grey850;
    background-color: color.$background-grey;
}

.disabled {
    color: color.$disabled;
    background-color: color.$background-disabled;
}


