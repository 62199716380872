.wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.plus {
    cursor: pointer;
}

.role {
    margin: 0 !important;
}
