@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid #EDEDED;

    .btn {
	min-width: 0;
	z-index: 2;
    }

}

.title {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.svgBlock {
    cursor: pointer;
}

.activeSvg {
    svg {
	path {
	    stroke: $blueA600;
	}
    }
}

.content {
    .category {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 20px;

	.categoryBlock {
	    display: flex;
	    align-items: center;
        position: relative;
        z-index: 3;
	}

	.btnBlock {
	    display: flex;
	    align-items: center;

	    .text {
		font-weight: 700;
		font-size: 14px;
		color: #939393;
		margin-right: 32px;
		cursor: pointer;
	    }
	}
    }
}

.choiceMenu {
    margin-right: 24px;
    max-width: none !important;
}

// .offer-settings {
//     display: flex;
//     height: 100%;

//     >.dynamic-field-block {
//         padding-right: 52px;
//         border-right: 1px solid #EDEFF1;

//         &.disable {
//             display: none;
//         }

//         >h1 {
//             font-size: 35px;
//             font-weight: 500;
//             color: #000000;
//             margin: 30px 0 0;
//         }

//         >h3 {
//             font-size: 22px;
//             font-weight: 500;
//             color: #000000;
//             margin: 18px 0;
//         }

//         >.field-list {
//             >.field {
//                 width: 385px;
//             }
//         }
//     }

// }


// .header {
//     display: flex;
//     padding: 0 25px 0 36px;
//     width: 100%;
//     box-sizing: border-box;

//     >.name-box {
//         display: flex;
//         align-items: center;

//         &:hover {
//             cursor: pointer;
//         }

//         >span {
//             font-weight: 400;
//             font-size: 16px;
//             color: #272727;
//             padding: 6px 9px;
//             border-radius: 7px;
//             background: #D6E9FF;
//             transition: all .2s ease-in-out;

//             &.disable {
//                 background: #FFFFFF;
//             }
//         }

//         >svg {
//             margin-left: 16px;
//             fill: #CDCDCD;
//             transition: fill .2s ease-in-out;

//             &:hover {
//                 fill: #A6A6A6;
//                 cursor: pointer;
//             }
//         }
//     }

//     >span {
//         color: #969696;
//         font-size: 14px;
//         font-weight: 700;
//         margin-left: auto;
//         padding: 23px 15px;
//         margin-right: 10px;

//         &:hover {
//             cursor: pointer;
//         }
//     }
// }
