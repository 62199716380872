@import "/src/styles/color";

.wrapper {
    max-width: 370px;
    margin-bottom: 24px;
    position: relative;
    transition: all 0.2s ease-in;

    &:hover {
	.picture {
	    border: 1px solid $grey450;
	    transition: all 0.2s ease-in;
	}
    }
}

.picture {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    width: 370px;
    height: 180px;
    margin-bottom: 6px;
    transition: all 0.2s ease-in;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: $background-preview;
    .preview {
	z-index: 1;
	max-height: 100%;
	max-width: 100%;
    }
}

.title {
    font-weight: 500;
    font-size: 16px;
    color: $black;
    margin: 6px 0;
}

.comment {
    font-weight: 400;
    font-size: 14px;
    color: #939393;
    line-height: 19px;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}
