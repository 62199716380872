@import "/src/styles/color";

.wrapper {
    width: 1230px;
    min-height: 600px;
    height: 100%;
    font-family: 'Inter', sans-serif;
    padding: 32px 0 12px 40px;
    display: flex;
    justify-content: space-between;
}

.title_block {
    padding-bottom: 12px;
    border-bottom: 1px solid #D9D9D9;

    .search {
        border: none;

        div {
            margin-right: 10px;
        }

        input {
            padding-right: 0;
        }
    }
}

.left_side {
    padding-right: 20px;
    max-width: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin-top: 36px;
    max-height: 600px;
    margin-right: 10px;

    &::-webkit-scrollbar {
        width: 6px;
        background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
        background: #99C8FF;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #EBEBEB;
    }

    .sticky_tabs {
		display: flex;
		justify-content: space-between;
        position: sticky;
        background: white;
        top: 0;
        z-index: 10;
    }
}

.modules_list {
    margin-top: 36px;
}

.right_side {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 390px;
    flex-grow: 1;
    justify-content: space-between;
}

.categories {
    max-height: 500px;
    max-width: 390px;
    height: 100%;
    display: flex;
}

.modalContentCss {
    padding-right: 0;
}

.tagsBlock {
    margin-top: 25px;
    display: flex;
    align-items: center;
}

.createTagBtn {
    padding: 5px 10px;
    border-radius: 4px;
    display: inline-flex;
    background-color: $blueA600;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    font-size: 15px;

    span {
        color: $white;
        margin-right: 5px;
    }
}

.tag {
    margin-bottom: 0 !important;
}
