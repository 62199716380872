@import "/src/styles/color";

.wrapper {
    min-width: 520px;
    position: relative;
    z-index: 1;
}

.input_wrapper {
    padding: 15px 16px;
    border-radius: 320px;
    border: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    cursor: pointer;
    min-height: 54px;

    input {
	border: none;
	outline: none;
	font-weight: 400;
	font-size: 14px;
	flex-grow: 1;
    }

    input::-webkit-search-cancel-button {
	display: none;
    }
}

.icon {
    path {
	transition: all 0.2s ease-in-out;
	stroke: $black
    }
}

.active_icon {
    transition: all 0.2s ease-in-out;

    path {
	stroke: $blueA600
    }
}

.plus {
    font-size: 14px;
    font-weight: 400;
    color: $grey
}
