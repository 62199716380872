@import "default_colors";

//Панели инструментов
$panelElementHover: #0075FF;
$panelElementHoverBG: #D6E9FF;
$paddingPanelElement: 15px;
$borderRadiusPanelElement: 7px;
$panelElementColor: #7F8184;
$panelElementMargin: 0 0 4px 0;
$actionColorOnClick: #6a00ff;

// Сокет индикатор
$socket_connected: $color_status_success;
$socket_disconnected: $color_status_error;
$socket_sending: $color_main_accent;
$socket_connecting: $color_status_warning;

// Компоненты
$frame_hover: #BEBEBE;
$frame_focus: $color_main_accent;
$frame_editable: #FFB82E;
$module_frame: #8a0fff;

$highlight_area_background: rgba(0, 117, 255, 0.2);
$highlight_area_border: $color_main_accent;

$picture_area_background: repeating-linear-gradient(
								135deg,
								$color_main_accent,
								$color_main_accent 10px,
								#FFFFFF 10px,
								#FFFFFF 20px);
$picture_area_border: $color_main_accent;
$picture_highlight_area: #FFFFFFB2;

//шаблон отдельного инструмента тулбара
%tpl_Tool {
	position: relative;
	height: 58px;
	width: 58px;
	fill: #7F8184;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	margin: 0 0 4px 0;
	cursor: pointer;
	&:hover {
		background: $panelElementHoverBG;
		fill: $panelElementHover
	}
}

//  Позиционирование элементов
%fc {
	display: flex;
	justify-content: start;
	align-items: center;
}

// Отступ страниц конструктора
$marginPage: 30px;
