.sendOfferIcon {
    path {
	stroke: #249C5C !important;
    }
}

.sendOfferText {
    color: #249C5C !important;
}

.deleteOfferIcon {
    path {
	stroke: #FF3636 !important;
    }
}

.deleteOfferText {
    color: #FF3636 !important;
}

.cards_actions {
    width: 315px;
    max-height: none;
    position: relative;
    top: 0;
    right: 0;
}
