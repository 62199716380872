@import "/src/styles/color";

.category_list {
    overflow-y: auto;
    max-height: 216px;
    width: 100%;
    margin-top: 12px;
}

.category_list::-webkit-scrollbar {
    width: 4px;
}

.category_list::-webkit-scrollbar-track {
    background: $grey200;
    border-radius: 8px;
}

.category_list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 3px solid $grey850;
}

.search_category {
    background-color: transparent;
}

.tag_list {
    overflow-y: auto;
    max-height: 305px;

    &::-webkit-scrollbar {
        width: 6px;
        background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
        background: #99C8FF;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #EBEBEB;
    }
}
