.add_block {
    margin: 16px 0 32px 0;
}

.filtration_bar {
    align-items: start;
    margin-bottom: 16px;
}

.filters {
    gap: 16px;
}

.filtration_bar, .filters, .add_block {
    display: flex;
}

.filters, .add_block {
    align-items: center;
}

.add_block, .filtration_bar {
    justify-content: space-between;
}
