.socket-indicator_canceled {
  display: flex;
  align-content: center;

  > span {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 14px;
  }
}
