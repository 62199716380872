.figure-panel__fill-input {
	background: #FFFFFF;
	border: 1px solid #EEEEEE;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	cursor: pointer;
}

.figure-panel__fill-input::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 4px;
	padding: 3px;
}

.figure-panel__fill-input::-webkit-color-swatch {
	border: none;
	border-radius: 4px;
	padding: 3px;
}