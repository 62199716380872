/* Контейнер для выбора даты и времени */
.date-time-picker_container {
  position: absolute;
  top: -25px;
  left: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* Контейнер для месяца в календаре */
.react-datepicker__month-container {
  padding: 4px;
}

/* Основные стили для календаря */
.react-datepicker {
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: "Inter", sans-serif;
}

/* Стили для поля ввода даты и времени */
.date-time-picker {
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  background-color: #ffffff;
  color: #272727;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.date-time-picker:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Стили для выбранных и выделяемых дат */
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0075FF;
  color: #fff;
}

.react-datepicker__day--selected:hover {
  background-color: #0075FF;
}

/* Стили для заголовка календаря */
.react-datepicker__header {
  background-color: #ffffff;
  color: #ffffff;
  border-bottom: none;
  border-top-left-radius: 12px;
  padding: 0.6rem 0.4rem 0.2rem 0.4rem;
}

.react-datepicker-time__header {
  color: #0075FF;
  font-weight: 500;
}

.react-datepicker__header--time {
  padding: 12px 8px 14px 8px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 12px;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: black;
  margin-bottom: 4px;
  font-weight: 500;
}

.react-datepicker__current-month {
  color: #0075FF;
}

.react-datepicker__month {
  margin: 0 4px 4px 4px;
}

/* Стили для навигации в календаре */
.react-datepicker__navigation {
  height: 24px;
  width: 24px;
}

.react-datepicker__navigation--previous {
  border-right-color: #ffffff;
  top: 10px;
  left: 15px;
}

.react-datepicker__navigation--next {
  border-left-color: #ffffff;
  top: 10px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 100px;
}

/* Стили для выбранной даты с клавиатуры */
.react-datepicker__day--keyboard-selected {
  background-color: white;
}

.react-datepicker__day--keyboard-selected:hover {
  border-radius: 0.5rem;
  background-color: #0075FF;
  color: white;
}

/* Стили для контейнера времени */
.react-datepicker__time-container {
  border-left: 1px solid #eeeeee;
}

.react-datepicker__time-container 
.react-datepicker__time {
  border-bottom-right-radius: 6px;
}

.react-datepicker__time-container 
.react-datepicker__time 
.react-datepicker__time-box 
ul.react-datepicker__time-list {
  min-height: 224px !important;
  padding: 0;
}

.react-datepicker__time-container 
.react-datepicker__time 
.react-datepicker__time-box 
ul.react-datepicker__time-list 
li.react-datepicker__time-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.3rem;
}

.react-datepicker__time-container 
.react-datepicker__time 
.react-datepicker__time-box 
ul.react-datepicker__time-list 
li.react-datepicker__time-list-item--selected {
  background-color: #268bff;
  color: white;
  font-weight: normal;
}

.react-datepicker__time-container 
.react-datepicker__time 
.react-datepicker__time-box 
ul.react-datepicker__time-list 
li.react-datepicker__time-list-item--selected:hover {
  background-color: #0075FF;
}

/* Стили для скроллбара */
.react-datepicker__time-container 
.react-datepicker__time 
.react-datepicker__time-box 
ul.react-datepicker__time-list {
  &::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 6px;
	}
}