@import '/src/styles/color';

.wrapper {
    position: absolute;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.content {
    white-space: pre;
    display: block;
    padding: 9px 13px;
    color: $white;
    font-size: 13px;
    font-weight: 400;
    background-color: #6D6D6D;
    border-radius: 4px;

    position: relative;

    &:after {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 50%;
	transform: translateX(-50%);
	border-width: 6px 6px 0;
	border-style: solid;
	border-color: #6D6D6D transparent transparent;
    }
}

.hidden {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
}
