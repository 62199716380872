@import "../../base/default_colors";

.text-panel__item {
	display: inline-flex;
	list-style-type: none;
	margin-right: 8px;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 4px;
	position: relative;

	&:hover {
		border: 1px solid $color_icon_stroke_hover;
		border-radius: 3px;
	}

	&.active {
		background: #D6E9FF;
		svg {
			stroke: $color_icon_hover;
		}
	}

	svg {
		stroke: #7F8184;
	}
}
