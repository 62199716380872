.panel-zoom__zoom {
	display: flex;
	justify-content: center;
	align-items: center;
	&.hidden {
		display: none;
	}
	.panel-zoom__zoom-size {
		padding: 8px;
	}
}
