.generalBtn {
    cursor: pointer;
    border-radius: 47px;
    font-weight: 700;
    font-size: 14px;
    font-family: Inter, sans-serif;
    outline: none !important;
    line-height: 18px;
    transition: all .2s ease-in-out;
}

.block {
    display: inline-block;
}

.blockWithChildren {
    display: flex;
    align-items: center;
    margin: 0 auto;
}
