.page-container_container {
	position: relative;

	.page-header {
		opacity: 1;
		pointer-events: all;
	}
}

.page-loader_container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 60px;
}

.page-loader_page {
	position: relative;
	width: 210mm;
	height: 297mm;
	margin-top: 30px;

	border-radius: inherit;
	background: #fafafa !important;
	overflow: hidden !important;
	color: #fafafa !important;

	&::after {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		content: "";
		border-radius: 20px;
		background: linear-gradient(90deg,
						#fafafa 0%,
						#ffffff 50%,
						#fafafa 100%);
		animation: move-empty-item 1s infinite;
	}
}
