@import "/src/styles/color";

.wrapper {
    padding: 0 20px 20px 20px;
    margin-top: 20px;
    height: 300px;
    overflow-y: scroll;
    min-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;

    &::-webkit-scrollbar {
	width: 6px;
	background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
	background: #99C8FF;
	border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background: #EBEBEB;
    }
}

.not_found {
    width: 100%;
    border: none !important;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
