@import "/src/styles/color";

.wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.title {
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    color: $black;
    text-align: center;
    max-width: 250px;
    margin: 48px 0 8px 0;
}

.content {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    padding: 16px 32px;
    cursor: pointer;

    span {
	font-size: 14px;
	font-weight: 600;
	color: $blueA600;
	margin-left: 8px;
    }
}
