@import "../../base/default_colors";

.object-panel__label {
	display: grid;
	align-items: center;
	margin: 0;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: $color_text_grey;
	text-align: start;
}
