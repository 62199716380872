@import "/src/styles/color";

.wrapper {
    position: relative;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
    max-width: 360px;
}

.title {
    color: $grey850;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 16px;
}

.button {
    width: 167px;
    max-height: 44px;
    font-size: 14px;
    margin-right: 10px;
}

.input_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .input {
	max-width: 160px;
	min-width: 160px;
	width: 160px;
	margin-top: 0;
    }
}
