.wrapper, .controls {
    margin-top: 24px;
}

.controls {
    gap: 12px;
    display: flex;
    margin-bottom: 24px;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .loader {
        margin-left: 10px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

