.wrapper {
}

.icon {
    cursor: pointer;
}

.menu {
    top: 30px;
    right: -5px;
}
