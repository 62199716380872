@import '/src/styles/color';

.wrapper {
    border-radius: 8px;
    color: $black;
    padding: 8px 16px;
    border: none;
    background-color: #EDEDED;
    font-size: 14 px;
    color: #272727;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.selected {
    background-color: $background-selected-category;
    color: #272727;
}
