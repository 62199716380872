@import "../../base/default_colors";

.object-panel__buttons-item {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	background: $color_main_basic;
	cursor: pointer;
	border: 1px solid transparent;
	&:hover {
		border: 1px solid $color_icon_stroke_hover;
		border-radius: 3px;
	}
	&:nth-child(7n) {
		margin-right: 0;
	}
	&:nth-child(n + 8) {
		margin-bottom: 0;
	}
	&.disable {
		svg.svg-fill {
			fill: $color_text_disabled;
		}
		.svg-stroke {
			stroke: $color_text_disabled;
		}
	}
	&_active {
		background: #D6E9FF;
		border-radius: 4px;
		svg.svg-fill {
			fill: $color_button_tertiary_press !important;
		}
		.svg-stroke {
			stroke: $color_button_tertiary_press !important;
		}
	}
	input {
		width: 80px;
		cursor: pointer;
		padding-left: 10px;
	}
	svg.svg-fill {
		fill: $color_icon_default;
	}
	.svg-stroke {
		stroke: $color_icon_default;
	}
}

.object-panel__radius-angles-button {
	// margin-left: 8px;
}
