@import "/src/styles/color";

.wrapper {
    padding: 15px 26px;
    background-color: $white;
    border-bottom: 2px solid #EDEDED;
    border-top: 2px solid transparent;
    min-width: 390px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s;
    position: relative;
    border-left: 2px solid transparent;

    &:hover {
	background-color: #DCE2FA;
	transition: all 0.3s;
    }
}

.action_block {
    display: flex;
    align-items: center;

    div {
	cursor: pointer;
	margin-left: 10px;
    }
}

.text {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: $grey850;
}

.active {
    background-color: #DCE2FA;
}

.drag_icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.draggable {
    cursor: grabbing;
}

.dragging {
    border-left: 2px solid $blueA600;
    background-color: $white !important;
}

.underdragging {
    border-top: 2px solid $blueA600;
}
