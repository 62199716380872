.picture-action-layer {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  padding: 16px;
  z-index: 500;
  pointer-events: none;
  width: fit-content;
  margin-left: -32px;
}

.picture-action-layer_replace {
  display: flex;
  align-items: center;
  color: white;
  padding: 6px 12px;
  background: #00000080;
  border-radius: 32px;
  outline: none;
  font-weight: 600;
  font-size: 12px;
  border: none;
  cursor: pointer;
  pointer-events: auto;

  //> svg {
  //  margin-right: 6px;
  //}

  &.compact-mode {
    margin-right: 0;

    > svg {
      margin-right: 0;
    }
    > span {
      display: none;
    }
  }
}

.picture-action-layer_edit {
  display: flex;
  align-items: center;
  color: white;
  padding: 6px 12px;
  background: #00000080;
  border-radius: 32px;
  outline: none;
  font-weight: 600;
  font-size: 12px;
  border: none;
  cursor: pointer;

  > svg {
    margin-right: 6px;
  }

  &.compact-mode {
    margin-top: 10px;

    > svg {
      margin-right: 0;
    }
    > span {
      display: none;
    }
  }
}
