.modal {
    width: 100%;
    max-width: 1320px;
}

.wrapper {
    width: 98%;
    height: 800px;
    overflow-y: scroll;
    padding-right: 25px;

    &::-webkit-scrollbar {
        width: 6px;
        background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
        background: #99C8FF;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #EBEBEB;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 22px;
}

.title_block {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .title {
	font-weight: 600;
	font-size: 26px;
    }
}

.search {
    border: none;
}

.not_found {
    text-align: center;
    border-top: 1px solid #EDEDED;
    padding: 15px 20px;
}
