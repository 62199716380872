@import "/src/styles/color";

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid $grey370;
    border-radius: 6px;
    width: 352px;
    cursor: text;
}

.input_wrapper {
    width: 230px;
    margin-left: 15px;
    margin-right: 5px;
}

.emoji {
    top: 18px;
    left: 15px;
    cursor: pointer;
    width: 30px;
    height: 30px;

    .bg {
	background: $background-grey;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
    }
}

.input {
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 3px 10px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    max-width: 100%;
    color: transparent;
    caret-color: black;
    background: transparent;
}

.colorex {
    div {
	margin: 0;
    }
}

.tooltip,
.roleTooltip,
.colorTooltip {
    position: absolute;
    background-color: #5A5A5A;
    color: $white;
    padding: 13px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;

    &::after {
	content: '';
	position: absolute;
	border: 10px solid transparent;
	border-left: 10px solid #5A5A5A;
	transform: rotate(-90deg);
    }
}

.roleTooltip {
    bottom: 30px;
    left: -20px;

    &::after {
	right: 66px;
	top: -17px;
    }
}

.colorTooltip {
    bottom: -15px;
    left: 216px;

    &::after {
	right: 23px;
	top: -17px;
    }
}
