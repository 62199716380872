.add_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 24px 0;
}

.filter {
    margin-bottom: 16px;
}
