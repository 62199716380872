.wrapper {
    max-width: 420px;
}

.description {
    line-height: 1.3;
}

.btn_block {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
