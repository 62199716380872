@import "x-coordinates";
@import "yCoordinates";
@import "height";
@import "width";
@import "rotate";

.object-panel__error {
	font-size: 10px;
	white-space: nowrap;
	display: none;
	color: darkred;
}