@import "src/styles/color";

.wrapper {
    position: relative;
    padding-top: 40px;
    min-width: 325px;
    font-family: 'Inter', sans-serif;
}

.title {
    color: $grey850;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 16px;
}

.button {
    width: 170px;
    margin-top: 16px;
    max-height: 44px;
    font-size: 14px;
    height: 44px;

    &:disabled,
    button[disabled] {
	padding: 11px 22px;
    }
}
