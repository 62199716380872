.magnetic-line {
	background-color: rgba(206, 0, 32, 0.65);
	position: absolute;
	opacity: 0;
	z-index: 10000;

	&.show {
		opacity: 1;
	}
}
