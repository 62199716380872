@import "/src/styles/_color.scss";

.wrapper {
    position: relative;
    font-family: 'Inter', sans-serif;
    max-width: 360px;
}

.title {
    color: $grey850;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 16px;
}

.descr {
    margin-bottom: 20px;
    line-height: 1.3;
}

.buttonBlock {
    width: 100%;
    display: flex;
    align-items: center;
}

.button {
    width: 130px;
    max-height: 44px;
    font-size: 14px;
    margin-right: 10px;
}
