@import "../../base/default_colors";

.img-panel__cancel-btn {
	cursor: pointer;
	background: $color_button_secondary_default;
	border-radius: 7px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 130%;
	color: #0075FF;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 2px solid $color_button_secondary_stroke;
	&:hover {
		background: $color_button_secondary_hover;
	}
}