@import '/src/styles/color';

.wrapper {
    border: 2px solid #CEE4FF;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 13px;
    cursor: pointer;
    background-color: $white;
}
