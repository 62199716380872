@import '/src/styles/color';

.wrapper {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 12px;
    left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid $blueA600;
}

.wrapper, .wrapper img {
    border-radius: 50%;
    max-width: 100%;
    z-index: 10;
}

.light {
    background-color: #CEE4FF;
}
.bright {
    background-color: $blueA600;
}
