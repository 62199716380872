.object-panel__options {
	position: absolute;
	padding: 18px;
	border-radius: 8px;
	text-align: center;
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 1);
	overflow: hidden;
	display: none;
	z-index: 1000;
	transform: translate(-80px, 34px);
}

.inputs-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 6px;
}

.object-panel__item {
	padding: 8px 14px;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0;
}

.object-panel__item:hover {
	background-color: rgb(224, 222, 222);
}

.object-panel__item.active {
	background-color: rgb(238, 231, 231);
}
