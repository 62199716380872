@import "/src/styles/color";

.wrapper, .focused {
    font-family: 'Input', sans-serif;
    margin-top: 16px;
    padding: 16px 24px;
    background: $white;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    min-width: 300px;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    transition: all 0.2s ease;
    cursor: text;
}

.subtitle {
    font-weight: 400;
    font-size: 13px;
    color: #939393;
    margin-top: 8px;
}

.input {
    border: none;
    font-weight: 400;
    font-size: 18px;
    color: $grey850;
    width: 100%;
    padding: 2px 0;

    &:focus {
	outline: none;
    }

    &::placeholder {
	color: #939393;
	font-size: 18px;
	font-weight: 400;
    }
}

.focused {
    border: 1px solid $blueA600;
}
