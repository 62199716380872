.userCard {
    padding: 3px 5px;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 7px;
    cursor: pointer;
}

.picture {
    width: 33px;
    height: 33px;
    margin-right: 16px;
    border-radius: 50%;
}

.userCardText {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #343434;
}
