@import "/src/styles/color";

.title {
    margin: 0 0 12px;
    font-size: 18px;
    font-weight: 500;
    color: $black;
}

.dropdown_input {
    padding: 15px 16px;
    border-radius: 320px;
    border: 1px solid #EDEDED;
    width: 100%;
    outline: none;
    font-weight: 400;
    font-size: 14px;
}

input::-webkit-search-cancel-button {
    display: none;
}

.reset_btn {
    border: none;
    background-color: transparent;
    padding: 0;
    color: $grey;
    cursor: pointer;
    margin-top: 14px;
}
