.exampleEnterPopUp {
    opacity: 0;
    transform: scale(0.9);
}

.exampleEnterActivePopUp {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
}

.exampleExitPopUp {
    opacity: 1;
}

.exampleExitActivePopUp {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 200ms, transform 200ms;
}


.exampleEnterDropDown {
    opacity: 0;
    transform: translateY(-10%);
}

.exampleEnterActiveDropDown {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms, transform 300ms;
}

.exampleExitDropDown {
    opacity: 1;
}

.exampleExitActiveDropDown {
    opacity: 0;
    transform: translateY(-10%);
    transition: opacity 200ms, transform 300ms;
}


.exampleEnterRightAppearance {
    opacity: 0;
    transform: translateX(30%);
    //transform: translateX(16%);
}

.exampleEnterActiveRightAppearance {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 300ms;
}

.exampleExitRightAppearance {
    opacity: 1;
}

.exampleExitActiveRightAppearance {
    opacity: 0;
    transform: translateX(30%);
    transition: opacity 200ms, transform 300ms;
}
