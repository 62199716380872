.template-menu__btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: start;
  padding: 10px 16px 10px 10px;
  transition: all .2s ease;
  border: none;
  background-color: transparent;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  white-space: nowrap;
}
