.wrapper {
    border: 1px solid #EDEDED;
    border-radius: 4px;
    min-height: 308px;
    min-width: 319px;
    max-width: 319px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main {
    padding: 20px;
}

.title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .text {
	font-weight: 500;
	font-size: 18px;
    }
}
