.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: 8px;
}

.category {
    border-radius: 60px;
}
