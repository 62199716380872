@import "../../base/default_colors";

.table-panel__title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	color: $color_field_text;
	margin: 0 0 10px 0;
}
