@import "/src/styles/color";

.infoBoxWrapper {
    position: fixed;
    z-index: 99;
    bottom: 36px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 100%;
    pointer-events: none;
}

.infoBox {
    background: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 0 8px;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: auto;

    .item {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 0;

	.icon {
	    padding: 9px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    border: 2px solid #CEE4FF;
	    border-radius: 32px;
	    margin-right: 8px;
	    cursor: pointer;
	}

	.text {
	    margin-right: 18px;
	    font-weight: 400;
	    font-size: 14px;
	    color: $grey850;
	}

	.dynamicText {
	    width: 185px;
	}
    }
}
