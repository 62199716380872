.wrapper {
    position: absolute;
    top: 12px;
    right: 4px;
    display: flex;
    align-items: center;
    z-index: 2;

    .item {
	background-color: white;
	border-radius: 50%;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
	padding: 10px;
	margin-right: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
    }
}
