@import '/src/styles/color';

.wrapper {
    display: inline-flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-size: 16px;
    font-weight: 500;
}

.user {
    border-radius: 8px;
    border: 2px solid #CEE4FF;

    &:hover {
	background-color: transparent !important;
	color: $black
    }
}
