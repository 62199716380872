@import "../../base/default_variables";

.table-panel__border-color {
	@extend %fc;
	flex-direction: row-reverse;

	h4 {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 130%;
	}
}
