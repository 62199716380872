@import "../../base/default_colors";

.img-panel__apply-btn {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: $color_button_primary_default;
	border: 2px solid $color_button_primary_default;
	color: $color_text_white;
	margin-right: 8px;
	&:hover {
		background: $color_button_primary_hover;
		border: 2px solid $color_button_primary_hover;
	}
}