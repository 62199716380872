@import "/src/styles/color";

.bright {
    color: $white;
    background-color: $blueA600;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
	background-color: $blue450;
    }

    &:focus {
	border-color: $blueA600;
    }

    &:disabled {
	background-color: $blue90;
    }
}

.light {
    background-color: transparent;
    color: $blueA600;
    border: 2px solid $blue90;

    &:hover {
	transition: all .2s ease-in-out;
	background-color: $blue90;
    }

    &:disabled,
    button[disabled] {
	background: #F5F7F9;
	color: $disabled;
	border-color: transparent;

	svg {
	    path {
		fill: $disabled
	    }
	}
    }
}

.transparent {
    color: $blueA600;
    border: none;
    background-color: transparent;
}

.bright, .light, .transparent {
    padding: 12px 20px;
    border-radius: 32px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
