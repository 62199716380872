.header {
	text-align: end;

}

.btn {
	position: relative;
	/* Показываем элемент при наведении на кнопку */
	&:hover .btn__hint {
		visibility: visible;
		opacity: 1;
	}
}
.btn__hint {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.3s ease;

	border-radius: 4px;
	padding: 5px 9px;
	background: rgba(0, 0, 0, 0.8);
	color: #FFFFFF;
	font-family: Inter, sans-serif;
	font-size: 11px;
	font-weight: 500;
	line-height: 13.2px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	position: absolute;
	top: 105%;
	&::after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 100%;
		transform: translateX(-50%);
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #000000CC; /* Цвет стрелочки */

	}

}
.btn__icon {
	cursor: pointer;
}
