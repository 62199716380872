@import "/src/styles/color";

.wrapper {
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    outline: none;

    .text {
        color: $blueA600;
        font-weight: 700;
        font-size: 14px;
        margin-left: 11px;
    }
}
