@import "../../../../styles/color";

.wrapper {
    width: 250px;
    height: 250px;
    border-radius: 16px;
    border: 1px solid $grey250;
    box-sizing: border-box;
    background-color: $white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.selected {
    border: 1px solid $blueA600;
}

.info {
    padding: 16px 16px 20px;
	width: 100%;
}

.title {
    font-weight: 400;
    font-size: 15px;
    color: $grey850;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;
    border: none;
    background: transparent;
    padding: 4px 4px;
	display: inline-block;
	max-width: 100%; /* Ограничение ширины */
	width: auto; /* Автоматическая ширина на основе содержимого */
	white-space: nowrap; /* Запрещаем перенос строк */
	overflow: hidden; /* Скроем текст, если он выходит за пределы */
	text-overflow: ellipsis; /* Добавляем многоточие, если текст обрезан */
}

.titleActive {
	position: absolute;
    background: #D6E9FF;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 4px 4px;
	max-width: none;
	z-index: 2511;
	min-width: 218px;
	width: 218px;
	//transition: width 0.05s ease; /* Плавный переход для ширины */
}

.description {
    font-weight: 500;
    font-size: 13px;
    color: $blueA600;
    padding-bottom: 2px;
}

.sizer {
	visibility: hidden;
	white-space: pre;
	position: absolute;
	top: -9999px; /* Вместо 5000px */
	left: -9999px; /* Устанавливаем его за пределы видимой области */
	font-weight: 400;
	font-size: 15px;
	color: $grey850;
	word-wrap: break-word;
}
