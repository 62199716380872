@import "/src/styles/color";

.button {
    padding: 10px 22px;
    color: #868686;
    border: 2px solid $grey250;
    min-width: 120px;
    max-width: 140px;
    background-color: transparent;
    transition: all .2s ease-in-out;

    &:hover {
	transition: all .2s ease-in-out;
	background-color: $grey250;
    }

    &:disabled,
    button[disabled] {
	background-color: #F5F7F9;
	border: none;
	color: $disabled;
    }
}

.active {
    border-color: $grey250;
}

.process {
    height: 46px;
}
