@import '/src/styles/color';

.edit_name_wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
	cursor: pointer;
    }
}

.input {
    font-weight: 600;
    font-size: 29px;
    color: $grey850;
    margin: 0;
    border: 0;
    display: inline-flex;
    outline: none;
    padding: 0;
    max-width: 700px;
}

.title_wrapper {
    padding: 3px 0;
}

.edit_name_wrapper, .input {
    background-color: transparent;
}
