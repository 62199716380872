.wrapper {
    padding: 29px 0;
}

.go_back_btn {
    margin: 29px 0 0 29px;
}

.info_wrapper {
    justify-content: space-between;
}

.info {
    gap: 67px;
}

.side_info {
    align-items: start;
    flex-direction: column;
    gap: 32px;
}

.info_wrapper, .side_info, .info {
    display: flex;
}
