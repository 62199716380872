@import "/src/styles/color";

.wrapper {
    padding: 8px 32px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
	background-color: $blueA600;
	color: $white;
	transition: 0.1s linear;

	svg {
	    path {
		transition: 0.1s linear;
		stroke: $white !important;
	    }
	}
    }
}

.picture {
    position: absolute;
    left: 15px;
}
