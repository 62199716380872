.img-panel__buttons-wrap {
	display: flex;
	justify-content: end;
	margin-right: 20px;

	button {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 130%;
		padding: 11px 22px;
		border-radius: 32px;
	}
}
