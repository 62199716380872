@import "/src/styles/color";

.wrapper, .info, .arrow, .more {
    display: flex;
    align-items: center;
}

.wrapper {
    justify-content: space-between;
    padding: 20px 32px;
    border: 1px solid #EDEDED;
    border-radius: 16px;
    transition: all 0.3s ease-in-out;
    position: relative;
    cursor: pointer;
}

.opened {
    border-radius: 16px 16px 0 0;
}

.arrow {
    justify-content: center;
}

.user_icon {
    margin-right: 8px;
}

.name, .employee_count {
    font-weight: 500;
    font-size: 16px;
}

.name {
    margin-right: 24px;
    color: $grey850
}

.employee_count {
    letter-spacing: -0.005em;
    color: #9C9C9C;
}


.arrow {
    padding: 4px;
    margin-right: 28px;
}

.more {
    justify-content: center;
    cursor: pointer;
    padding: 5px;

    path {
	transition: all .2s linear;
    }

    &:hover {
	path {
	    transition: all .2s linear;
	    stroke: $grey850;
	}
    }
}
