$color_main_accent: #0075ff;
$color_main_additional: #272727;
$color_main_basic: #FFFFFF;

$color_background_white: #FFFFFF;
$color_background_grey: #EAEDF1;
$color_background_disabled: #ECECEC;

$color_text_black: #272727;
$color_text_white: #FFFFFF;
$color_text_grey: #939393;
$color_text_disabled: #C2C2C2;

$color_stroke_grey: #EDEDED;
$color_stroke_focus: #0075FF;

$color_field_text: #272727;
$color_field_grey: #EDEDED;
$color_field_focused: #0075FF;
$color_field_placeholder: #939393;
$color_field_title: #939393;
$color_field_placeholder_click: #D1D1D1;

$color_status_warning: #FFB82E;
$color_status_warning_hover: #FFB82E;
$color_status_error: #FF3636;
$color_status_error_hover: #FF6B6B;
$color_status_success: #249C5C;
$color_status_success_hover: #2EC775;

$color_button_primary_default: #0075FF;
$color_button_primary_hover: #4E9FFF;
$color_button_primary_focused: #0075FF;
$color_button_primary_press: #2488FF;
$color_button_primary_disabled: #D6E9FF;

$color_button_secondary_default: #FFFFFF;
$color_button_secondary_stroke: #D6E9FF;
$color_button_secondary_hover: #D6E9FF;
$color_button_secondary_press: #ADD3FF;

$color_button_tertiary_default: #0075FF;
$color_button_tertiary_hover: #D6E9FF;
$color_button_tertiary_press: #0075FF;

$color_icon_default: #7F8184;
$color_icon_hover: #0075FF;
$color_icon_active: #0075FF;
$color_icon_background_active: #D6E9FF;
$color_icon_disabled: #C2C2C2;
$color_icon_stroke_hover: #A6A6A6;

$color_tag_employee_text: #0075FF;
$color_tag_employee_stroke: #99C8FF;
$color_tag_employee_background: #F0F7FF;
$color_tag_employee_background_hover: #DBEBFF;

$color_tag_admin_text: #E24360;
$color_tag_admin_stroke: #F1A7B4;
$color_tag_admin_background: #FDF2F4;
$color_tag_admin_background_hover: #FAE0E5;

$color_tag_super_admin_text: #63AA58;
$color_tag_super_admin_stroke: #0075FF;
$color_tag_super_admin_background: #0075FF;
$color_tag_super_admin_background_hover: #E9F3E7;


