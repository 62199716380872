@import '/src/styles/color';

.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-family: 'Inter', sans-serif;
    border: 1px solid $grey200;
    border-top: none;
    border-radius: 0;
    transition: all 0.2s linear;

    &:hover {
	transition: all 0.2s linear;
	background: #F0F7FF;
    }
}

.block {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0 20px 32px;
}

.shortInfo {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.picture {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 15px;
}

.name {
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: $grey850;
    width: 300px;
}

.mainInfo {
    display: flex;
    align-items: center;
}

.phone {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #343434;
    margin-right: 27px;
    width: 130px;
}

.email {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.005em;
    color: #489CEA;
    cursor: pointer;
}

.more {
    cursor: pointer;
    padding: 32px 37px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
	transition: all .2s linear;
    }

    &:hover {
	path {
	    transition: all .2s linear;
	    stroke: $grey850;
	}
    }
}
