.wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    width: 1300px;
    min-height: 600px;
    height: 100%;
    padding: 32px 0 12px 40px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-right: 10px;
    padding-right: 20px;
    height: 100%;
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;

    &::-webkit-scrollbar {
        width: 6px;
        background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
        background: #99C8FF;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #EBEBEB;
    }
}

.search {
    border: none;

    div {
        padding-left: 0 !important;
        margin-right: 10px;
    }

    input {
        padding-right: 0;
    }
}

.search_block {
    max-width: 100%;
}

.categories {
    flex: 1;
    display: flex;
}

.manage_bar {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    max-height: 100%;
}
