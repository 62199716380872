@import "../../base/default_colors";

.page-properties__item {
	position: relative;
	list-style-type: none;
	margin-right: 8px;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg {
		stroke: $color_icon_default;
	}

	&.active {
		svg {
			stroke: $color_icon_active;
		}
	}

	&:hover {
		border: 1px solid $color_icon_stroke_hover;
		border-radius: 3px;
	}
}

.page-properties__edit-page {
	margin-left: 8px;
}
