.sign {
    position: absolute;
    right: 25px;
    top: 28px;
    z-index: 100;
}

.password {
    cursor: pointer;
}

.calendar {
    cursor: pointer;
}
