@import "./_change-color";
@import "./_back-btn";
@import "./_modules";
@import "./_modes-wrapper";
@import "./_gen-counter";
@import "_error-layers-indicator";

.top-bar {
    grid-template-columns: auto 1fr 60px auto;
    position: fixed;
    top: 20px;
    right: 20px;
    bottom: auto;
    left: 20px;
    display: none;
    background: #FFFFFF;
    border-radius: 7px;
    padding: 0 14px 0 0;
    height: 62px;
    z-index: 130;
}

.top-bar.show {
    display: grid;
    align-items: center;
}

.top-bar.top-bar-with-note {
    grid-template-columns: 500px 1fr 60px auto;
}
