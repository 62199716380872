@import '/src/styles/color';

.wrapper {
    position: sticky;
    top: 0;
}

.go_back_btn {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid $grey250;
    transition: all .2s ease-in-out;
}

.content {
    font-weight: 500;
    color: $grey;
    padding: 17px 27px;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 6px;

    span {
	white-space: pre;
    }

    svg {
	margin-right: 19px;
    }
}
