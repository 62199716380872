@import "../panel-components/panel-components";
@import "../panel-action/panel-action";
@import "../panel-modules/panel-modules";

.toolbar {
    border-radius: 7px;
    width: fit-content;
    display: none;
    position: fixed;
    top: 122px;
    right: auto;
    bottom: auto;
    left: 20px;
    z-index: 1000;
}