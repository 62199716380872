.wrapper {
    padding: 4px 12px;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 32px;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.3s ease-in-out;
}

.ADMIN {
    border-color: #C0EBB9;
    color: #63AA58;

    svg path {
	stroke: #63AA58;
    }
}

.SUPER_ADMIN {
    border-color: #FFDDE3;
    color: #E24360;

    svg path {
	stroke: #E24360;
    }
}

.EMPLOYEE {
    border-color: #B2DAFF;
    color: #3F97E9;

    svg path {
	stroke: #3F97E9;
    }
}

.SERVICE_ADMIN {
    border-color: #d26bd3;
    color: #ca29d2;

    svg path {
	stroke: #ca29d2;
    }
}

.INTEGRATOR, {
    border-color: #d3a26b;
    color: #d27e29;

    svg path {
	stroke: #d27e29;
    }
}

.active {
    svg {
	transform: rotate(-180deg);
    }
}

.openable {
    cursor: pointer;
}
