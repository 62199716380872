@import "/src/styles/color";

.tabsBlock {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #EDEDED;
}

.tab {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: $grey900;
    padding: 8px 13px;
    margin-right: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    border-bottom: 2px solid transparent;
    text-decoration: none;

    &:hover {
	cursor: pointer;
	color: #81BBFF;
	border-bottom: 2px solid $blue90;
    }

}

.activeTab {
    border-bottom: 2px solid $blueA600;
    color: $blueA600;

    &:hover {
	color: $blueA600;
	border-bottom: 2px solid $blueA600;
    }
}

.disabled {
    color: $grey500;
    cursor: default !important;

    &:hover {
	color: $grey500;
	border-bottom: 2px solid transparent;
    }
}
