@import "../../base/default_variables";

.page-properties__orientation {
	display: flex;
	margin: 0 0 4px 0;
	padding: 0;
	& .color-input_container {
		margin-right: 0;
	}
}
