.table-input-modal__close {
	position: absolute;
	right: 30px;
	top: 30px;
	cursor: pointer;
	svg {
		stroke: #C2C2C2;
	}
	&:hover svg {
		stroke: #A6A6A6;
	}
}