.wrapper {
    font-family: 'Inter', sans-serif;
    flex-grow: 1;
    display: flex;
    flex-direction: column
}

.title {
    margin-top: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 16px;
}
