@import "/src/styles/color";

.title {
    font-weight: 600;
    font-size: 29px;
    color: $grey850;
    margin: 0;
}

.underlined {
    margin-top: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 16px;
}
