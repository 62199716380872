.socket-notification {
  position: fixed;
  display: block;
  padding: 10px 120px;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  background: #F4F3FE;
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  user-select: none;
  transition: all 1s ease-in-out;
  opacity: 1;
  pointer-events: none;
  z-index: 10000;

  &.hide {
    opacity: 0;
  }
}
