@import "../../../base/default_variables";

.page-frame__angle-trigger {
  pointer-events: none;
  //opacity: 0;

  &.top {
    &:after {
      position: absolute;
      left: -4px;
      top: -4px;
      content: "";
      width: 8px;
      height: 8px;
      background: $color_main_accent;
    }
    &:before {
      position: absolute;
      right: -4px;
      top: -4px;
      content: "";
      width: 8px;
      height: 8px;
      background: $color_main_accent;
    }
  }

  &.bottom {
    &:after {
      position: absolute;
      left: -4px;
      bottom: -4px;
      content: "";
      width: 8px;
      height: 8px;
      background: $color_main_accent;
    }
    &:before {
      position: absolute;
      right: -4px;
      bottom: -4px;
      content: "";
      width: 8px;
      height: 8px;
      background: $color_main_accent;
    }
  }
}
