.wrapper {
    display: flex;
    align-items: center;
    font-family: Inter, serif;

    .text {

        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        text-align: left;
    }
    .link {
        font-family: Inter, serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        text-align: left;
        margin-left: 24px;
        color: #0075FF;
        text-decoration: none;
    }
}
