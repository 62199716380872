@import "/src/styles/color";

.wrapper {
    display: flex;
    align-items: center;
    width: auto;
    gap: 12px;
}

.reset_icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;

    &.focus {
        path {
            stroke: $blueA600;
            transition: all .2s ease-in-out;
        }
    }
}

@media (min-width: 1px) {
    .wrapper {
        min-width: 500px;
    }
}
