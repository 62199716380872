.wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
