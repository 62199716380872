@import "../../base/default_variables";

.page-properties__repeating-image-size-label {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: $color_text_grey;
	margin: 0;
}
