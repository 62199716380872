.btnBlockWrapper {
    display: flex;
    align-items: center;
}

.btnBlock {
    display: flex;
    align-items: center;
}

.create_btn {
    margin-left: 16px;
}
