@import "/src/styles/color";

.wrapper {
    background-color: $background-grey;
    border-radius: 24px;
    padding: 32px;
    max-width: 455px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
    white-space: nowrap;
}

.description {
    font-weight: 400;
    font-size: 16px;
    color: $black;
}

.tariff_name {
    margin-top: 8px;
    font-weight: 500;
    font-size: 23px;
    color: $black;
}

.expiration {
    margin-bottom: 16px;
}
