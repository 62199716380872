@import "/src/styles/color";

.wrapper {
    margin-top: 32px;
}

.table, .table tbody {
    width: 100%;
    margin-top: 32px;
    border-spacing: 0;
}

.first_card {
    border-radius: 24px 24px 0 0;
}

.last_card {
    border-radius: 0 0 24px 24px;
    border-bottom: 1px solid $light-grey;
}

.single {
    border-radius: 24px 24px 24px 24px;
    border: 1px solid $light-grey;
}

.table tbody tr:first-child td:first-child {
    border-top-left-radius: 24px;
}

.table tbody tr:first-child td:last-child {
    border-top-right-radius: 24px;
}

.table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 24px;
}

.table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 24px;
}