@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
}

.title {
    font-weight: 600;
    font-size: 30px;
    color: $grey850;
    margin-top: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 16px;
}

.searchBlock {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.minButton {
    width: 170px;
    min-width: 185px !important;
}

.usersBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    overflow-y: scroll;
    margin-top: 19px;

    &::-webkit-scrollbar {
	width: 6px;
	background-color: $grey200;
    }

    &::-webkit-scrollbar-thumb {
	background: $grey200;
	border-radius: 44px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: $white;
    }

}

.btnChild {
    width: 135px;
    margin: 0;
}

.skeleton_loading_card {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: #F3F3F9;
    width: 100%;
    padding: 24px;
    margin-bottom: 19px;

    &:not(&:last-child) {
	margin-bottom: 19px;
    }

    .skeleton {
	animation: skeleton-loading 1s linear infinite alternate;
    }

    .upper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 18px;

	.status {
	    width: 200px;
	    height: 25px;
	}

	.more {
	    height: 24px;
	    width: 24px;
	    border-radius: 50%;
	}
    }

    .main {
	display: flex;
	align-items: center;
	margin-bottom: 18px;

	.picture {
	    width: 33px;
	    height: 33px;
	    border-radius: 50%;
	    margin-right: 16px;
	}

	.name {
	    width: 500px;
	    height: 22px;
	    margin-right: 200px;
	}

	.info {
	    width: 150px;
	    height: 22px;
	}
    }

    .roles {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.role {
	    width: 125px;
	    height: 25px;

	    &:not(&:last-child) {
		margin-right: 8px;
	    }
	}
    }

    .picture {
	border-radius: 50%;
	width: 30px;
	height: 30px;
    }
}

@keyframes skeleton-loading {
    0% {
	background-color: #E9ECF6;
    }

    100% {
	background-color: hsl(200, 6%, 90%);
    }
}
