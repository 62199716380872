@import "../../../base/default_variables";

.page-frame__graphic-module {
  width: 100%;
  height: 100%;
  //border: 1px solid $module_frame;
  border: 1px solid transparent;

  &.focus {
    border: 1px solid $frame_focus;
  }
}
