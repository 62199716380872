@import "../../base/default_colors";

.text-panel__size-input {
	height: 100%;
	border: 1px solid #EDEDED;
	border-radius: 4px;
	padding: 7px 30px 7px 8px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	display: flex;
	align-items: center;
	color: $color_text_black;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;	/* Remove default arrow */
	background: url('../../../../../image/arrow.svg') no-repeat 80% 50%;
	width: 68px;
	cursor: pointer;

}