@import "/src/styles/color";

.changeCardInfo {
    position: absolute;
    top: 50px;
    right: 20px;
    background: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 8px 0;
    min-width: 200px;
    z-index: 10 !important;

    .smileWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
    }

    .item {
	display: flex;
	align-items: center;
	padding: 10px 16px;
	cursor: pointer;
	transition: all .2s ease;

	&:hover {
	    background: $background-grey;
	    transition: all .2s ease;

	    .text {
		color: $blueA600 !important;
	    }

	    path {
		stroke: $blueA600 !important;
	    }
	}

	path {
	    stroke: $black;
	    transition: all .2s ease;
	}

	.text {
	    font-weight: 400;
	    font-size: 14px;
	    color: $grey850;
	    margin-left: 10px;
	    transition: all .2s ease;
	}
    }
}
