@import "./_section";
@import "./_row";
@import "./_row-caption";
@import "./_row-combination";
@import "./table";

.context-menu {
	position: absolute;
	background: #FFFFFF;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	cursor: pointer;
	min-width: 256px;
	z-index: 1000;
}
