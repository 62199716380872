@import "/src/styles/color";

.searchBlock {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.empty_button {
    display: flex;
    align-items: center;
    gap: 8px;
}

.rolesBlock {
    border-radius: 12px;
    margin-top: 17px;
}

.noRolesBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;

    .title {
	margin-top: 50px;
	font-weight: 600;
	font-size: 29px;
	text-align: center;
	color: $grey850;
    }

    .descr {
	font-weight: 400;
	font-size: 18px;
	text-align: center;
	color: $grey600;
	margin-top: 16px;
	margin-bottom: 24px;
    }

    .btn {
	width: 137px;
    }
}

.skeleton_loading_card {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .role {
	border-radius: 4px;
	margin-top: 10px;
	margin-right: 8px;
	width: 110px;
	height: 25px;
    }

    &:not(&:last-child) {
	margin-bottom: 19px;
    }

    .skeleton {
	animation: skeleton-loading 1s linear infinite alternate;
    }
}

@keyframes skeleton-loading {
    0% {
	background-color: #E9ECF6;
    }

    100% {
	background-color: hsl(200, 6%, 90%);
    }
}
