@import "color";
@import "ai-modals-styles";

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-Thin.ttf");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../shared/fonts/Inter-Black.ttf");
    font-weight: 900;
    font-style: normal;
}

* {
    font-family: Inter, sans-serif;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    user-select: none;
    min-height: 100vh;

    &::-webkit-scrollbar {
	width: 6px;
	background-color: #EEEEEE;
    }

    &::-webkit-scrollbar-thumb {
	background: #EEEEEE;
	border-radius: 44px;
    }

    &::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #fff;
    }
}

#root {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;

    .collection {
	flex-grow: 1;
    }

    .Toastify {
	flex-grow: 0;
    }
}

button {
    border: none;
    background-color: transparent;
}

.Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.service-version {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 6px;
    user-select: none;
    font-size: .8em;
    opacity: .4;
    background: #EEEEEE;
    border-radius: 4px;
    box-shadow: 1px 2px 2px #b2b2b2;
    margin: 6px;
    transition: all .2s ease-in-out;
    color: #707070;
    cursor: help;

    &:hover {
	opacity: 1;
    }
}

.test-server-notification {
    position: absolute;
    bottom: 60px;
    z-index: 9999;
    margin: 40px;
    background: rgb(33, 33, 33);
    padding: 10px;
    opacity: .9;
    user-select: none;
    transition: all .2s ease-in;
    text-align: center;
    color: #ffd800;
    cursor: pointer;

    &:hover {
	opacity: .1;
    }

    .disable {
	pointer-events: none;
    }

    h1 {
	text-align: center;
	position: relative;
	color: #ffffff;
	margin: 0 -30px 30px -30px;
	padding: 10px 0;
	text-shadow: 2px 2px 8px black;
	background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3) 5px, #bb9d00 5px, #bb9d00 10px);
	-moz-box-shadow: 0 2px 0 rgba(0, 0, 0, .3);
	-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .3);
	box-shadow: 0 2px 0 rgba(0, 0, 0, .3);
	font-size: 24px;
    }

    p {
	line-height: 18px;
    }

    h1:before, h1:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-color: transparent;
	bottom: -10px;
    }

    h1:before {
	border-width: 0 20px 10px 0;
	border-right-color: #222;
	left: 0;
    }

    h1:after {
	border-width: 0 0 10px 20px;
	border-left-color: #222;
	right: 0;
    }

}

.ai-modal-loader {
	padding: 20px;
}
.ai-modal-disabled {
	opacity: 30%;
}
