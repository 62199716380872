@import "../../base/default_variables";
@import "./panel-lists__order";

.panel-lists {
	background: #FFFFFF;
	border-radius: $borderRadiusPanelElement;
	padding: 4px;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
	display: none;
}
