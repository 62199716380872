.wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;

    .collection {
	flex-grow: 1;
    }

    .Toastify {
	flex-grow: 0;
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.info_box {
    position: fixed;
    bottom: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
}
