@import "/src/styles/color";

.collection {
    display: flex;

    > .left-side-bar {
	border-right: 1px solid $grey250;
	display: flex;
	flex-direction: column;

	> .section-tab {
	    position: sticky;
	    top: 0;

	    > .item {
		display: flex;
		border-bottom: 1px solid $grey250;
		transition: all .2s ease-in-out;

		&:hover {
		    cursor: pointer;
		    background: #F2F2F2;

		    > div {
			background: $blue90;
		    }

		    > span {
			color: $blueA600;

			> svg {
			    > path {
				stroke: $blueA600;
			    }
			}
		    }
		}

		&.active {
		    > div {
			background: $blueA600;
		    }
		}

		> div {
		    width: 6px;
		    transition: all .2s ease-in-out;
		}

		> span {
		    font-weight: 500;
		    color: $black;
		    padding: 17px 27px;
		    font-size: 16px;
		    display: flex;
		    align-items: center;

		    > svg {
			margin-right: 19px;
		    }
		}
	    }

	}
    }

    > .tab-block {
	display: flex;
	flex-direction: column;
	padding: 0 41px;
	width: 100%;

	> .top-side-bar {
	    position: sticky;
	    top: 0;
	    display: flex;
	    border-bottom: 1px solid $grey200;
	    background-color: $white;
	    // z-index: 1;
	    height: 74px;

	    > .container-tab {
		display: flex;

		> div {
		    padding: 22px 42px 22px 0;
		    transition: all .2s ease-in-out;

		    &:hover {
			cursor: pointer;

			> span {
			    color: #81BBFF;
			    border-bottom: 2px solid $blue90;
			}
		    }

		    &.active {
			> span {
			    border-bottom: 2px solid $blueA600;
			    color: $blueA600;
			}
		    }

		    > span {
			padding: 21px 0 23px 0;
			color: $grey600;
			font-weight: 500;
			font-size: 22px;
			transition: all .2s ease-in-out;
			border-bottom: 2px solid transparent;
		    }
		}
	    }

	    > .search-box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 4px 0 auto;

		&.focus {
		    > svg {
			fill: $blueA600;
		    }
		}

		> svg {
		    transition: all .2s ease-in-out;
		    fill: #CDD0D3;
		    margin-right: 20px;

		    &.focus {
			fill: $blueA600;
		    }
		}

		> input {
		    color: $black;
		    font-size: 17px;
		    outline: none;
		    border: none;
		}

		> .clear-box {
		    display: flex;
		    align-items: center;
		    height: 100%;
		    padding: 0 0 0 12px;
		    transition: all .2s ease-in-out;
		    opacity: 0;
		    pointer-events: none;

		    &.show {
			opacity: 1;
			pointer-events: all;
		    }

		    > svg {
			fill: $grey650;
		    }

		    &:hover {
			cursor: pointer;

			> svg {
			    fill: $blueA600;
			}
		    }
		}
	    }
	}

    }
}

.list-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
