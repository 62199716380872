@import "/src/styles/color";

.wrapper {
    align-items: center;
    padding: 24px;
    width: 100%;
    border: 1px solid $light-grey;
    border-bottom: none;
    background-color: $background-disabled;

    td {
        padding: 24px;
        text-align: center;
    }

    .tariff_name {
        font-weight: 700;
        text-align: left;
    }
}

.active {
    background-color: $background-grey;
}

.tariff_name {
    font-weight: 500;
    width: 50%;
}

.side_text_duration {
    font-weight: 400;
    width: 10%;
    min-width: 200px;
    white-space: nowrap;
}

.side_text_start_date {
    font-weight: 400;
    width: 50%;
    min-width: 200px;
}

.tariff_name, .side_text_duration, .side_text_start_date {
    font-size: 16px;
    color: $black;
}

.reactivate {
    width: 15%;
}