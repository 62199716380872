.choiced_tag_list {
    display: flex;
    align-items: center;
    gap: 8px;
}

.tag {
    display: inline-flex;
    border-radius: 60px;
}
