@import '/src/styles/color';

.wrapper {
    flex-direction: column;
}

.name {
    font-size: 23px;
    font-weight: 500;
    color: $black;
}

.list_wrapper, .wrapper {
    display: flex;
    align-items: start;
    gap: 16px;
}

.list_wrapper {
    align-items: center;

    svg path {
	stroke: #7F8184;
    }
}

.no_data {
    font-weight: 700;
    font-size: 14px;
    color: $grey;
}
