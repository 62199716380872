.picture-block__loading-picture {
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  animation: skeleton-loading-picture 0.5s linear infinite alternate;
}

@keyframes skeleton-loading-picture {
  0% {
    background-color: hsl(200, 20%, 85%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}