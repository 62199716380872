.wrapper {
    cursor: pointer;
}
.category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 16px;
    text-align: left;
    border-radius: 9px;

    &:hover {
        background: rgba(244, 244, 244, 1);
    }
}

.tags_block {
    padding: 15px 20px 5px 20px;
    display: flex;
    flex-wrap: wrap;
}
.category_name {
    width: 100%;
    text-align: left;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    cursor: pointer;
}
.openTitle {
    font-weight: 600;
}
.childs {
    padding-left: 8px;
}
