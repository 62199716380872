@import "/src/styles/color";

.wrapper, .open {
    border: 1px solid #EAEDF1;
    border-radius: 43px;
    padding: 6px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
}

.open {
    background-color: #CDE4FF;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-top: 2px;

        path {
            stroke: #272727;
        }
    }
}

.text {
    color: $black;
    font-size: 14px;
    margin-right: 6px;
    white-space: nowrap;
}

.selected {
    background-color: #CDE4FF;
}
