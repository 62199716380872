/* Arial */
@font-face {
	font-family: 'Arial';
	font-weight: 400;
	font-style: normal;
	src: local('Arial'),
	url('../../../shared/fonts/arial/arial.woff2') format('woff2'),
	url('../../../shared/fonts/arial/arial.woff') format('woff');
	font-display: swap;
}
@font-face {
	font-family: 'Arial';
	font-weight: 700;
	font-style: normal;
	src: local('Arial Bold'),
	url('../../../shared/fonts/arial/arial_bold.woff2') format('woff2'),
	url('../../../shared/fonts/arial/arial_bold.woff') format('woff');
	font-display: swap;
}
@font-face {
	font-family: 'Arial';
	font-weight: 700;
	font-style: italic;
	src: local('Arial Bold Italic'),
	url('../../../shared/fonts/arial/arial_bold_italicmt.woff2') format('woff2'),
	url('../../../shared/fonts/arial/arial_bold_italicmt.woff') format('woff');
	font-display: swap;
}
@font-face {
	font-family: 'Arial';
	font-weight: 400;
	font-style: italic;
	src: local('Arial Italic'),
	url('../../../shared/fonts/arial/arial_italicl.woff2') format('woff2'),
	url('../../../shared/fonts/arial/arial_italicl.woff') format('woff');
	font-display: swap;
}
/* Arial end */

/* Dela Gothic One */
@font-face {
	font-family: 'Dela Gothic One';
	font-weight: 400;
	font-style: normal;
	src: local('Dela Gothic One'),
	url('../../../shared/fonts/dela-gothic-one/dela-gothic-one-regular.woff2') format('woff2'),
	url('../../../shared/fonts/dela-gothic-one/dela-gothic-one-regular.woff') format('woff'),
	url('../../../shared/fonts/dela-gothic-one/dela-gothic-one-regular.ttf') format('truetype');
	font-display: swap;
}
/* Dela Gothic One end */

/* Fira Sans */
@font-face {
	font-family: 'Fira Sans';
	font-weight: 400;
	font-style: normal;
	src: local('Fira Sans'),
		url('../../../shared/fonts/fira-sans/firasans-regular.woff') format('woff'),
		url('../../../shared/fonts/fira-sans/firasans-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Fira Sans';
	font-weight: 700;
	font-style: normal;
	src: local('Fira Sans Bold'),
		url('../../../shared/fonts/fira-sans/firasans-bold.woff') format('woff'),
		url('../../../shared/fonts/fira-sans/firasans-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Fira Sans';
	font-weight: 700;
	font-style: italic;
	src: local('Fira Sans Bold Italic'),
		url('../../../shared/fonts/fira-sans/firasans-bolditalic.woff') format('woff'),
		url('../../../shared/fonts/fira-sans/firasans-bolditalic.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Fira Sans';
	font-weight: 400;
	font-style: italic;
	src: local('Fira Sans Italic'),
		url('../../../shared/fonts/fira-sans/firasans-italic.woff') format('woff'),
		url('../../../shared/fonts/fira-sans/firasans-italic.ttf') format('truetype');
	font-display: swap;
}
/* Fira Sans end */

/* Inter */
@font-face {
	font-family: 'Inter';
	font-weight: 400;
	font-style: normal;
	src: local('Inter'),
		url('../../../shared/fonts/inter/inter-regular.woff2') format('woff2'),
		url('../../../shared/fonts/inter/inter-regular.woff') format('woff'),
		url('../../../shared/fonts/inter/inter-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Inter';
	font-weight: 700;
	font-style: normal;
	src: local('Inter Bold'),
		url('../../../shared/fonts/inter/inter-bold.woff2') format('woff2'),
		url('../../../shared/fonts/inter/inter-bold.woff') format('woff'),
		url('../../../shared/fonts/inter/inter-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Inter';
	font-weight: 700;
	font-style: italic;
	src: local('Inter Bold Italic'),
		url('../../../shared/fonts/inter/inter-italic-bold.woff2') format('woff2'),
		url('../../../shared/fonts/inter/inter-italic-bold.woff') format('woff'),
		url('../../../shared/fonts/inter/inter-italic-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Inter';
	font-weight: 400;
	font-style: italic;
	src: local('Inter Italic'),
		url('../../../shared/fonts/inter/inter-italic.woff2') format('woff2'),
		url('../../../shared/fonts/inter/inter-italic.woff') format('woff'),
		url('../../../shared/fonts/inter/inter-italic.ttf') format('truetype');
	font-display: swap;
}
/* Inter end */

/* Jost */
@font-face {
	font-family: 'Jost';
	font-weight: 400;
	font-style: normal;
	src: local('Jost'),
		url('../../../shared/fonts/jost/jost-regular.woff2') format('woff2'),
		url('../../../shared/fonts/jost/jost-regular.woff') format('woff'),
		url('../../../shared/fonts/jost/jost-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Jost';
	font-weight: 700;
	font-style: normal;
	src: local('Jost Bold'),
		url('../../../shared/fonts/jost/jost-bold.woff2') format('woff2'),
		url('../../../shared/fonts/jost/jost-bold.woff') format('woff'),
		url('../../../shared/fonts/jost/jost-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Jost';
	font-weight: 700;
	font-style: italic;
	src: local('Jost Bold Italic'),
		url('../../../shared/fonts/jost/jost-bolditalic.woff2') format('woff2'),
		url('../../../shared/fonts/jost/jost-bolditalic.woff') format('woff'),
		url('../../../shared/fonts/jost/jost-bolditalic.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Jost';
	font-weight: 400;
	font-style: italic;
	src: local('Jost Italic'),
		url('../../../shared/fonts/jost/jost-italic.woff2') format('woff2'),
		url('../../../shared/fonts/jost/jost-italic.woff') format('woff'),
		url('../../../shared/fonts/jost/jost-italic.ttf') format('truetype');
	font-display: swap;
}
/* Jost end */

/* Kiwi Maru */
@font-face {
	font-family: 'Kiwi Maru';
	font-weight: 400;
	font-style: normal;
	src: local('Kiwi Maru'),
	url('../../../shared/fonts/kiwi-maru/kiwi-maru-regular.woff2') format('woff2'),
	url('../../../shared/fonts/kiwi-maru/kiwi-maru-regular.woff') format('woff'),
	url('../../../shared/fonts/kiwi-maru/kiwi-maru-regular.ttf') format('truetype');
	font-display: swap;
}
/* Kiwi Maru end */

/* Kosugi Maru */
@font-face {
	font-family: 'Kosugi Maru';
	font-weight: 400;
	font-style: normal;
	src: local('Kosugi Maru'),
	url('../../../shared/fonts/kosugi-maru/kosugi-maru-regular.woff2') format('woff2'),
	url('../../../shared/fonts/kosugi-maru/kosugi-maru-regular.woff') format('woff'),
	url('../../../shared/fonts/kosugi-maru/kosugi-maru-regular.ttf') format('truetype');
	font-display: swap;
}
/* Kosugi Maru end */

/* Martian Mono */
@font-face {
	font-family: 'Martian Mono';
	font-weight: 400;
	font-style: normal;
	src: local('Martian Mono'),
	url('../../../shared/fonts/martian-mono/martian-mono-regular.woff2') format('woff2'),
	url('../../../shared/fonts/martian-mono/martian-mono-regular.woff') format('woff'),
	url('../../../shared/fonts/martian-mono/martian-mono-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Martian Mono';
	font-weight: 700;
	font-style: normal;
	src: local('Martian Mono Bold'),
	url('../../../shared/fonts/martian-mono/martian-mono-bold.woff2') format('woff2'),
	url('../../../shared/fonts/martian-mono/martian-mono-bold.woff') format('woff'),
	url('../../../shared/fonts/martian-mono/martian-mono-bold.ttf') format('truetype');
	font-display: swap;
}
/* Martian Mono end */

/* Merriweather */
@font-face {
	font-family: 'Merriweather';
	font-weight: 400;
	font-style: normal;
	src: local('Merriweather'),
		url('../../../shared/fonts/merriweather/merriweather-regular.woff2') format('woff2'),
		url('../../../shared/fonts/merriweather/merriweather-regular.woff') format('woff'),
		url('../../../shared/fonts/merriweather/merriweather-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Merriweather';
	font-weight: 700;
	font-style: normal;
	src: local('Merriweather Bold'),
		url('../../../shared/fonts/merriweather/merriweather-bold.woff2') format('woff2'),
		url('../../../shared/fonts/merriweather/merriweather-bold.woff') format('woff'),
		url('../../../shared/fonts/merriweather/merriweather-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Merriweather';
	font-weight: 700;
	font-style: italic;
	src: local('Merriweather Bold Italic'),
		url('../../../shared/fonts/merriweather/merriweather-bolditalic.woff2') format('woff2'),
		url('../../../shared/fonts/merriweather/merriweather-bolditalic.woff') format('woff'),
		url('../../../shared/fonts/merriweather/merriweather-bolditalic.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Merriweather';
	font-weight: 400;
	font-style: italic;
	src: local('Merriweather Italic'),
		url('../../../shared/fonts/merriweather/merriweather-italic.woff2') format('woff2'),
		url('../../../shared/fonts/merriweather/merriweather-italic.woff') format('woff'),
		url('../../../shared/fonts/merriweather/merriweather-italic.ttf') format('truetype');
	font-display: swap;
}
/* Merriweather end */

/* Oswald */
@font-face {
	font-family: 'Oswald';
	font-weight: 400;
	font-style: normal;
	src: local('Oswald'),
	url('../../../shared/fonts/oswald/oswald-regular.woff2') format('woff2'),
	url('../../../shared/fonts/oswald/oswald-regular.woff') format('woff'),
	url('../../../shared/fonts/oswald/oswald-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Oswald';
	font-weight: 700;
	font-style: normal;
	src: local('Oswald Bold'),
	url('../../../shared/fonts/oswald/oswald-bold.woff2') format('woff2'),
	url('../../../shared/fonts/oswald/oswald-bold.woff') format('woff'),
	url('../../../shared/fonts/oswald/oswald-bold.ttf') format('truetype');
	font-display: swap;
}
/* Oswald end */


/* Prosto One */
@font-face {
	font-family: 'Prosto One';
	font-weight: 400;
	font-style: normal;
	src: local('Prosto One'),
	url('../../../shared/fonts/prosto-one/prosto-one-regular.woff2') format('woff2'),
	url('../../../shared/fonts/prosto-one/prosto-one-regular.woff') format('woff'),
	url('../../../shared/fonts/prosto-one/prosto-one-regular.ttf') format('truetype');
	font-display: swap;
}
/* Prosto One end */

/* Roboto */
@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-style: normal;
	src: local('Roboto'),
		url('../../../shared/fonts/roboto/roboto-regular.woff2') format('woff2'),
		url('../../../shared/fonts/roboto/roboto-regular.woff') format('woff'),
		url('../../../shared/fonts/roboto/roboto-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: normal;
	src: local('Roboto Bold'),
		url('../../../shared/fonts/roboto/roboto-bold.woff2') format('woff2'),
		url('../../../shared/fonts/roboto/roboto-bold.woff') format('woff'),
		url('../../../shared/fonts/roboto/roboto-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: italic;
	src: local('Roboto Bold Italic'),
		url('../../../shared/fonts/roboto/roboto-bolditalic.woff2') format('woff2'),
		url('../../../shared/fonts/roboto/roboto-bolditalic.woff') format('woff'),
		url('../../../shared/fonts/roboto/roboto-bolditalic.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-style: italic;
	src: local('Roboto Italic'),
		url('../../../shared/fonts/roboto/roboto-italic.woff2') format('woff2'),
		url('../../../shared/fonts/roboto/roboto-italic.woff') format('woff'),
		url('../../../shared/fonts/roboto/roboto-italic.ttf') format('truetype');
	font-display: swap;
}
/* Roboto end */

/* Rubik */
@font-face {
	font-family: 'Rubik';
	font-weight: 400;
	font-style: normal;
	src: local('Rubik'),
		url('../../../shared/fonts/rubik/rubik-regular.woff2') format('woff2'),
		url('../../../shared/fonts/rubik/rubik-regular.woff') format('woff'),
		url('../../../shared/fonts/rubik/rubik-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Rubik';
	font-weight: 700;
	font-style: normal;
	src: local('Rubik Bold'),
		url('../../../shared/fonts/rubik/rubik-bold.woff2') format('woff2'),
		url('../../../shared/fonts/rubik/rubik-bold.woff') format('woff'),
		url('../../../shared/fonts/rubik/rubik-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Rubik';
	font-weight: 700;
	font-style: italic;
	src: local('Rubik Bold Italic'),
		url('../../../shared/fonts/rubik/rubik-bolditalic.woff2') format('woff2'),
		url('../../../shared/fonts/rubik/rubik-bolditalic.woff') format('woff'),
		url('../../../shared/fonts/rubik/rubik-bolditalic.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Rubik';
	font-weight: 400;
	font-style: italic;
	src: local('Rubik Italic'),
		url('../../../shared/fonts/rubik/rubik-italic.woff2') format('woff2'),
		url('../../../shared/fonts/rubik/rubik-italic.woff') format('woff'),
		url('../../../shared/fonts/rubik/rubik-italic.ttf') format('truetype');
	font-display: swap;
}
/* Rubik end */

/* Russo One */
@font-face {
	font-family: 'Russo One';
	font-weight: 400;
	font-style: normal;
	src: local('Russo One'),
	url('../../../shared/fonts/russo-one/russo-one-regular.woff2') format('woff2'),
	url('../../../shared/fonts/russo-one/russo-one-regular.woff') format('woff'),
	url('../../../shared/fonts/russo-one/russo-one-regular.ttf') format('truetype');
	font-display: swap;
}
/* Russo One end */

/* Seymour One */
@font-face {
	font-family: 'Seymour One';
	font-weight: 400;
	font-style: normal;
	src: local('Seymour One'),
		url('../../../shared/fonts/seymour-one/seymour-one-regular.woff2') format('woff2'),
		url('../../../shared/fonts/seymour-one/seymour-one-regular.woff') format('woff'),
		url('../../../shared/fonts/seymour-one/seymour-one-regular.ttf') format('truetype');
	font-display: swap;
}
/* Seymour One end */

/* Tektur */
@font-face {
	font-family: 'Tektur';
	font-weight: 400;
	font-style: normal;
	src: local('Tektur'),
	url('../../../shared/fonts/tektur/tektur-regular.woff2') format('woff2'),
	url('../../../shared/fonts/tektur/tektur-regular.woff') format('woff'),
	url('../../../shared/fonts/tektur/tektur-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Tektur';
	font-weight: 700;
	font-style: normal;
	src: local('Tektur Bold'),
	url('../../../shared/fonts/tektur/tektur-bold.woff2') format('woff2'),
	url('../../../shared/fonts/tektur/tektur-bold.woff') format('woff'),
	url('../../../shared/fonts/tektur/tektur-bold.ttf') format('truetype');
	font-display: swap;
}
/* Tektur end */

/* Ubuntu */
@font-face {
	font-family: 'Ubuntu';
	font-weight: 400;
	font-style: normal;
	src: local('Ubuntu'),
		url('../../../shared/fonts/ubuntu/ubuntu-regular.woff2') format('woff2'),
		url('../../../shared/fonts/ubuntu/ubuntu-regular.woff') format('woff'),
		url('../../../shared/fonts/ubuntu/ubuntu-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Ubuntu';
	font-weight: 700;
	font-style: normal;
	src: local('Ubuntu Bold'),
		url('../../../shared/fonts/ubuntu/ubuntu-bold.woff2') format('woff2'),
		url('../../../shared/fonts/ubuntu/ubuntu-bold.woff') format('woff'),
		url('../../../shared/fonts/ubuntu/ubuntu-bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Ubuntu';
	font-weight: 700;
	font-style: italic;
	src: local('Ubuntu Bold Italic'),
		url('../../../shared/fonts/ubuntu/ubuntu-bolditalic.woff2') format('woff2'),
		url('../../../shared/fonts/ubuntu/ubuntu-bolditalic.woff') format('woff'),
		url('../../../shared/fonts/ubuntu/ubuntu-bolditalic.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Ubuntu';
	font-weight: 400;
	font-style: italic;
	src: local('Ubuntu Italic'),
		url('../../../shared/fonts/ubuntu/ubuntu-italic.ttf') format('woff2'),
		url('../../../shared/fonts/ubuntu/ubuntu-italic.ttf') format('woff'),
		url('../../../shared/fonts/ubuntu/ubuntu-italic.ttf') format('truetype');
	font-display: swap;
}
/* Ubuntu end */

/* Unbounded */
@font-face {
	font-family: 'Unbounded';
	font-weight: 400;
	font-style: normal;
	src: local('Unbounded'),
		url('../../../shared/fonts/unbounded/unbounded-regular.woff2') format('woff2'),
		url('../../../shared/fonts/unbounded/unbounded-regular.woff') format('woff'),
		url('../../../shared/fonts/unbounded/unbounded-regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'Unbounded';
	font-weight: 700;
	font-style: normal;
	src: local('Unbounded Bold'),
		url('../../../shared/fonts/unbounded/unbounded-bold.woff2') format('woff2'),
		url('../../../shared/fonts/unbounded/unbounded-bold.woff') format('woff'),
		url('../../../shared/fonts/unbounded/unbounded-bold.ttf') format('truetype');
	font-display: swap;
}
/* Unbounded end */

@import "blocks/sketch-manipulator/sketch-manipulator";
@import "blocks/top-bar/top-bar";
@import "blocks/toolbar/toolbar";
@import "additional/wake-up";
@import "blocks/hint/hint";
@import "blocks/table-constructor/table-constructor";
@import "blocks/table-input-modal/table-input-modal";
@import "blocks/panel-zoom/panel-zoom";
@import "blocks/socket-indicator/socket-indicator";
@import "blocks/panel-lists/panel-lists";
@import "blocks/panel-ai/panel-ai";
@import "blocks/thumbnail-panel/thumbnail-panel";
@import "blocks/template-name/template-name";
@import "./base/common";
@import "./base/data_tooltip";
@import "./blocks/figure-panel/figure-panel";
@import "./blocks/t1001-select/t1001-select";
@import "blocks/img-panel/img-panel";
@import "blocks/table-panel/table-panel";
@import "blocks/page-properties/page-properties";
@import "blocks/propertyBar/propertyBar";
@import "blocks/text-panel/text-panel";
@import "blocks/object-panel/object-panel";
@import "blocks/page-header/page-header";
@import "blocks/page-container/page-container";
@import "blocks/context-menu/context-menu";
@import "blocks/layers-panel/layers-panel";
@import "blocks/sketch-manipulator/socket-notification";
@import "blocks/graphic-figure/graphic-figure";
@import "blocks/magnetic-line/magnetic-line";
@import "blocks/template-menu/template-menu";
@import "additional/text-editor-mext";
@import "mediaQueries/mediaQueries";
@import "blocks/trialNotification/trialNotification";
@import "blocks/ai-btn/ai-btn";
@import "blocks/gen-hint/gen-hint";
@import "blocks/tariffs-btn/tariffs-btn";

/*
Модальным окнам даём z-index = 10000,
панелькам даём z-index = 1000,
пользовательским областям (выделения) даем z-index = 999,
*/
