@import "/src/styles/color";

.dropdown_wrapper {
    box-shadow: 0 8px 16px 0 #00000014;
    width: 100%;
    border-radius: 8px;
    position: absolute;
    background-color: $white;

    .dropdown {
	  padding: 16px;
	  top: 60px;
    }
}
