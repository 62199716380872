.tariffs-btn {
	background: rgba(0, 117, 255, 1);
	width: 67px;
	height: 22px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	
	
	.tariffs-btn__link {
		color: rgba(255, 255, 255, 1);
		font-family: Inter;
		font-size: 13px;
		font-weight: 500;
		text-align: left;
		text-decoration: none;
	}
}
