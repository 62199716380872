@import "../../base/default_variables";

.text-panel__font-select {
	height: 100%;
	border: 1px solid #EDEDED;
	border-radius: 4px;
	padding: 7px 30px 7px 8px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	color: $color_text_black;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;	/* Remove default arrow */
	background: url('../../../../../image/arrow.svg') no-repeat 90% 50%;
	text-overflow: ellipsis;
	cursor: pointer;
	width: 100%;
}