@import "_row";
@import "_row-svg";
@import '_row-text';

.layers-panel {
	background: #FFFFFF;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 16px 10px;
	position: fixed;
	right: 350px;
	top: 412px;
	display: none;
	z-index: 1000;

	.layers-panel__row:last-child {
		margin-bottom: 0;
	}
}