@import "./_success";
@import "./_process";
@import "./_canceled";

.socket-indicator {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 145px;
  border-radius: 8px;
  background: #FFFFFF;
  opacity: 60%;
  padding: 6px 10px;
}
