@import "/src/styles/color";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    font-family: "Inter", sans-serif;
    flex-grow: 1;
}

.wrapper {
    display: block;
    padding: 37px 73px;
    border: 1px solid $grey200;
    border-radius: 12px;
    min-width: 450px;
}

.title {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 35px;
    color: $grey850;
    margin: 0;
    text-align: center;
}

.descr {
    font-weight: 500;
    color: $grey650;
    text-align: center;
    margin-top: 15px;

    .black {
	color: $grey850;
    }
}

.passwordDescr {
    margin-bottom: 32px;
}

.block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 39px auto 0;
    width: 426px;
}

.button {
    width: 100%;
}

.registration_btn {
    width: 100%;
}

.btnChild {
    order: 1;
    max-width: 284px;

    .puncts {
	margin-right: 18px;
    }
}

.codeBlock {
    display: flex;
    align-items: start;
}

.codeHint {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    margin-left: 23px;
}

.haveAccountWrapper {
    display: flex;
    flex-direction: column;
    margin: 25px auto 0;
}

.haveAccountBlock {
    display: flex;
    align-items: center;
    justify-content: center;
}

.haveAccountDescr {
    font-weight: 400;
    color: #939393;
    font-size: 14px;
    margin-right: 12px;
}

.haveAccountLink {
    font-weight: 400;
    font-size: 14px;
    color: $blueA600;
    cursor: pointer;
}

.picture {
    display: flex;
    justify-content: center;
}

.acceptedWrapper {
    border: 1px solid $blueA600;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 11px;
    min-width: 21px;
    min-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.agreementBlock {
    display: flex;
    justify-content: space-between;
    align-items: start;
    text-decoration: none;
    margin-bottom: 23px;
}

.agreementText {
    color: #939393;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 19px;
}

.agreementLink {
    text-decoration: none;
    color: $blueA600;
}
