.wrapper {
    display: flex;
    gap: 40px;
}

.contact_info, .info {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 16px;
}

.info .title {
    font-size: 23px;
    font-weight: 500;
}
