$white: #FFFFFF;
$black: #272727;

$grey50: #FAFAFA;
$grey100: #F5F5F5;
$grey150: #F0F0F0;
$grey200: #EEEEEE;
$grey250: #EDEFF1;
$grey300: #E0E0E0;
$grey320: #DEDEDE;
$grey350: #CDCDCD;
$grey370: #D1D1D1;
$grey400: #BDBDBD;
$grey450: #A6A6A6;
$grey500: #9E9E9E;
$grey550: #989898;
$grey590: #7F8184;
$grey600: #757575;
$grey650: #676767;
$grey700: #616161;
$grey750: #4D4D4D;
$grey800: #424242;
$grey850: #272727;
$grey900: #212121;

$red50: #FFEBEE;
$red100: #FFCDD2;
$red200: #EF9A9A;
$red300: #E57373;
$red400: #EF5350;
$red500: #F44336;
$red600: #E53935;
$red700: #D32F2F;
$red800: #C62828;
$red900: #B71C1C;

$redA100: #FF8A80;
$redA200: #FF5252;
$redA400: #FF1744;
$redA700: #D50000;

$yellow50: #FFFDE7;
$yellow100: #FFF9C4;
$yellow200: #FFF59D;
$yellow300: #FFF176;
$yellow400: #FFEE58;
$yellow500: #FFEB3B;
$yellow600: #FDD835;
$yellow700: #FBC02D;
$yellow800: #F9A825;
$yellow900: #F57F17;
$yellowA100: #FFFF8D;
$yellowA200: #FFFF00;
$yellowA400: #FFEA00;
$yellowA700: #FFD600;

$blue50: #E3F2FD;
$blue90: #D6E9FF;
$blue100: #BBDEFB;
$blue200: #90CAF9;
$blue300: #64B5F6;
$blue400: #42A5F5;
$blue450: #4E9FFF;
$blue500: #2196F3;
$blue600: #1E88E5;
$blue700: #1976D2;
$blue800: #1565C0;
$blue900: #0D47A1;

$blueA100: #82B1FF;
$blueA200: #448AFF;
$blueA400: #2979FF;
$blueA600: #0075FF;
$blueA700: #2962FF;

$green50: #E8F5E9;
$green100: #C8E6C9;
$green200: #C8E6C9;
$green300: #C8E6C9;
$green400: #C8E6C9;
$green500: #C8E6C9;
$green600: #C8E6C9;
$green700: #C8E6C9;
$green800: #C8E6C9;
$green900: #C8E6C9;

$greenA100: #B9F6CA;
$greenA200: #69F0AE;
$greenA400: #00E676;
$greenA700: #00C853;

$grey: #939393;
$light-grey: #EDEDED;
$background-grey: #EAEDF1;
$background-preview: #F0F0F0;
$background-disabled: #ECECEC;
$disabled: #C2C2C2;
$success: #249C5C;
$error: #FF3636;
$light-blue: #489CEA;

$background-selected-category: #CEE4FF;
