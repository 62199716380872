.colorex-container {
  position: absolute;
  left: 0;
  top: 0;
}

.colorex {
  position: fixed;
  top: var(--colorex-top);
  left: var(--colorex-left);
  padding: 6px;
  background: #FFFFFF;
  border-radius: 8px;
  z-index: 600;
}

.colorex-frequently-block {
  display: flex;
  border-bottom: 2px solid #EEEEEE;
  padding-bottom: 2px;
  margin-bottom: 2px;
  min-height: 28px;
}

.colorex-color-box {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;

  &:hover {
    > .colorex-color-circle {
      width: 26px;
      height: 26px;
    }
    .colorex-circle-strikethrough {
      transform: rotate(45deg) translate(-40%, 220%);
      transition: all .1s ease-in-out;
    }
  }
}

.colorex-color-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #868686;
  transition: all .1s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%) scale(1);
}

.colorex-colors-row {
  display: flex;
}

.colorex-circle-strikethrough {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #e12d3c;
  height: 3px;
  width: 100%;
  transform: rotate(45deg) translate(-40%, 160%);
  transition: all .1s ease-in-out;
  border-radius: 2px;
}
