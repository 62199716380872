@import '/src/styles/color';

.wrapper {
    position: relative;
}

.icon {
    width: 100%;
    height: 100%;
    border: 1px solid $blueA600;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}
