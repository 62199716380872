@import '/src/styles/color';

.wrapper {
    flex: 1;
    background-color: $background-grey;
}

.content, .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    gap: 16px;
    flex-direction: column;
}

.title {
    font-size: 29px;
    font-weight: 600;
}

.description {
    font-size: 16px;
    font-weight: 400;
}

.title, .description {
    color: $black;
}

.description, .btn {
    font-size: 16px;
}

.btn {
    border: none;
    cursor: pointer;
    color: $blueA600;
    font-weight: 700;
    padding: 16px 32px;
    background-color: transparent;
}
