@import "/src/styles/color";

.menuWrapper {
    background: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    top: 20px;
}

.tagWrapper {
    padding: 8px 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    border-radius: 5px;

    .choiceIcon {
	position: absolute;
	left: 14px;
	top: 6px;
    }

    &:hover {
	background-color: $blueA600;
	color: $white;
	transition: 0.1s linear;

	svg {
	    path {
		transition: 0.1s linear;
		stroke: $white !important;
	    }
	}
    }
}
