.social_networks {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;

    svg {
	cursor: pointer;
    }
}
