.object-panel__corner-radius-input {
	background: #FFFFFF;
	border: 1px solid #EEEEEE;
	border-radius: 8px;
	height: 32px;
	cursor: pointer;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		width: 21px;
		height: 21px;
		background: #656565;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 4px;
	}
}

.object-panel__border-color-input::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 8px;
	padding: 3px;
}

.object-panel__border-color-input::-webkit-color-swatch {
	border: none;
	border-radius: 8px;
	padding: 3px;
}
