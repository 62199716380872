@import "/src/styles/color";

.button {
    padding: 13px 22px;
    color: $white;
    border: none !important;
    min-width: 100px;
    max-width: 190px;
    transition: all .2s ease-in-out;

    &:hover {
	transition: all .2s ease-in-out;
	background-color: $blue450;
    }

    &:disabled,
    button[disabled] {
	background: #CEE4FF;
	color: $white !important;
    }
}

.active {
    background-color: $blueA600;
}

.text {
    margin-left: 10px;
    text-align: center;
}

.long {
    min-width: 170px;
}

.process {
    width: 190px;
    padding: 13px 22px !important;
}
