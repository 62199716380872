@import "../../base/default_colors";

.page-properties__colorInput {
	background: #FFFFFF;
	border-radius: 4px;
	position: relative;
	cursor: pointer;

	input {
		width: 24px;
		height: 24px;
		border: 1px solid #A6A6A6;

		&:after {
			//content: '';
			//position: absolute;
			//width: 20px;
			//height: 21px;
			//background: #FFFFFF;
			//top: 50%;
			//left: 50%;
			//transform: translate(-50%, -50%);
			//border-radius: 2px;
		}
	}
	//&:hover {
	//	input {
	//		border: 1px solid $color_icon_hover;
	//	}
	//}
}
.page-properties__colorInput::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 4px;
	padding: 0px;
}
.page-properties__colorInput::-webkit-color-swatch {
	border: none;
	border-radius: 4px;
	padding: 0px;
}