.skeleton_loading_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #F3F3F9;
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 15px;
    gap: 15px;

    .skeleton {
	animation: skeleton-loading 1s linear infinite alternate;
    }

    .main {
	display: flex;
	align-items: center;

	.name {
	    width: 306px;
	    height: 24px;
	    margin-left: 8px;
	    margin-right: 24px;
	}

	.count {
	    width: 125px;
	    height: 24px;
	}
    }

    .picture {
	border-radius: 50%;
	width: 30px;
	height: 30px;
    }
}

@keyframes skeleton-loading {
    0% {
	background-color: #E9ECF6;
    }

    100% {
	background-color: hsl(200, 6%, 90%);
    }
}
