@import '/src/styles/color';

.wrapper {
    padding: 10px 20px;
    font-size: 16px;
    color: $white;
    border-radius: 4px;
    font-weight: 400;
}

.active {
    background-color: #34A853
}

.disabled {
    background-color: #F14D4D;
}
