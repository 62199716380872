@import "/src/styles/color";

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.pin_item {
    padding: 20px 24px;
    max-width: 60px;
    max-height: 60px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    color: $grey850;
    margin-bottom: 24px;
}
