@import "_thumbnail";
@import "_number";

.thumbnail-panel {
	position: fixed;
	width: 283px;
	height: 75%;
	left: 94px;
	top: 114px;
	padding: 30px;
	background: #FFFFFF;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	z-index: 120;
	display: none;
	overflow: auto;

	&.show {
		display: flex;
		flex-direction: column;

	}
	canvas {
		width: 100% !important;
		height: auto !important;
		margin-bottom: 16px;
		border: 1px solid #A6A6A6;
		border-radius: 4px;
	}
}
.thumbnail-panel.show {
	display: flex;

}