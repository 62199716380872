@import "/src/styles/color";

.empty_template_box {
    display: flex;
    width: 420px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 46px;
}

.title {
    margin: 0;
    padding-top: 38px;
    font-size: 29px;
    font-weight: 600;
    text-align: center;
    color: $grey850;
}

.description {
    color: $grey600;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 25px
}
