@import "/src/styles/color";

.wrapper {
    font-family: 'Inter', sans-serif;
    padding-top: 28px;
}

.title {
    font-weight: 500;
    font-size: 23px;
    line-height: 130%;
    color: $grey850;
}

.btn {
    margin-top: 24px;
    height: 44px;
    padding: 10px 22px;
}
