@import "/src/styles/color";

.wrapper {
    display: flex;
    flex: 1;
}

.left_side_bar {
    border-right: 1px solid $grey250;
    display: flex;
    flex-direction: column;
}
