.wrapper, .categories, .notFound {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    flex-grow: 1;
    min-width: 390px;
}

.categories {
    border-top: 1px solid #EDEDED;
    max-height: 450px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        background: #EBEBEB;
    }

    &::-webkit-scrollbar-thumb {
        background: #99C8FF;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #EBEBEB;
    }
}

.search {
    width: 90%;
    margin: 0 auto 10px auto;
}

.notFound {
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 18px;
}
