.picture_wrapper {
    display: flex;
    align-items: start;
    justify-content: center;
    height: 76px;
    width: 100%;
    overflow: hidden;
    min-height: 160px;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid #EDEDED;

    img {
	width: 100%;
    }
}

.without_preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    width: 100%;
    overflow: hidden;
    min-height: 160px;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid #EDEDED;
}
