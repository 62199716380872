.wrapper {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    margin-bottom: 10px;
}

.header {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .title {
	font-weight: 500;
	font-size: 18px;
	margin-right: 11px;
    }
}

.open_header {
    border-bottom: 1px solid #EDEDED;
}
