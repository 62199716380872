@import "/src/styles/color";

.filterBox {
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: 32px;
    position: relative;
    min-width: 290px;

    .visible {
	display: flex;

	.text {
	    font-weight: 400;
	    font-size: 14px;
	    color: #939393;
	    margin-right: 8px;
	    display: block;
	}

	.choice {
	    cursor: pointer;
	    display: flex;
	    align-items: center;

	    .text {
		font-weight: 500;
		font-size: 15px;
		color: $grey850;
		margin-right: 5px;
	    }

	    .arrow {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 8px;
		height: 4px;
	    }

	    .rotate {
		transform: rotateX(180deg);
	    }
	}
    }

    .hidden {
	position: absolute;
	top: 30px;
	z-index: 100;
    }
}
