.bold {
	font-weight: 700;
}
.italic {
	font-style: italic;
}
.red-line {
	//margin-left: 20px;
}
.after-red-line {
	display: inline-block;
}
.§ {
	margin-top: 10px;
}
.page-frame__graphic-text * {
	white-space: break-spaces;
}
