@import '/src/styles/color';

.item_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1100px;

    .item {
	margin-right: 10px;
	border-radius: 6px;
	border: 2px solid transparent;
	margin-bottom: 5px;
	transition: all 0.2s ease;
	cursor: pointer;
    }

    .active {
	border: 2px solid $blueA700;
    }
}
