@import "../../../../styles/color";

.input {
    padding: 4px 10px;
    border-radius: 60px;
    background-color: $blue90;
    color: $blueA600;
    font-size: 12px;
    font-weight: 500;
    border: none;
    outline: none;
    width: 135px;
}

.input::placeholder {
    color: $blueA600;
    opacity: 1;
}
