@import "/src/styles/color";

.container {
    max-width: 850px;
    margin: 55px auto 0;
    font-family: 'Inter', sans-serif;
    line-height: 116%;
}

.title {
    font-weight: 600;
    font-size: 45px;
    margin-bottom: 35px;
    line-height: 116%;
}

.firstTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    margin-bottom: 22px;
}

.secondTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 155%;
    color: $grey850;
}

.secondTitleText {
    font-weight: 600;
    font-size: 20px;
    line-height: 155%;
    color: $grey850;
    margin-left: 14px;
}

.block {
    margin-bottom: 55px;
}

.standartText {
    font-weight: 400;
    font-size: 20px;
    color: #626262;
    line-height: 155%;
    margin-left: 15px;
}

.textWrapper {
    display: flex;
    align-items: start;
    margin-top: 30px;
}

.link {
    color: $blueA600;
    text-decoration: none;
}

.standartTextBlock {
    margin-top: 15px;
}
