.addInfo {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: rgba(127, 129, 132, 1);
    margin-bottom: 20px;
    width: 100%;
}

.selectWrapper {
    display: grid;
    margin-bottom: 34px;
    width: 100%;
}

.symbolsCount {
    position: absolute;
    right: 30px;
}
