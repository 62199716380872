@import "/src/styles/color";

.wrapper {
    position: relative;
}

.main {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    .title {
	color: $grey;
    }

    .selected {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;

	.active {
	    transform: rotateX(180deg);
	}

	span {
	    color: $black;
	}
    }
}

.select_area {
    position: absolute;
    top: 30px;
    z-index: 100;
    cursor: pointer;

    background: #FFFFFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
}
