@import "/src/styles/color";

.area {
    position: relative;
    width: 100%;
    margin-top: 8px;

    textarea {
        font-weight: 400;
        font-size: 18px;
        color: $grey850;
        width: 100%;
        max-width: 420px;
        max-height: 150px;
        border-radius: 4px;
        border-color: #EDEDED;
        padding: 24px;
        overflow: hidden;
        min-width: 50%;

        &:focus {
            outline: none;
        }

    }

    .placeholder {
        position: absolute;
        bottom: 16px;
        left: 24px;
        font-weight: 400;
        font-size: 13px;
        color: #939393;
    }
}

.charCounter {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #939393;
    margin-top: 8px;
}
