@import '/src/styles/color';

.wrapper {
    display: flex;
    border-bottom: 1px solid $grey250;
    transition: all .2s ease-in-out;

    &:hover {
	cursor: pointer;
	background: #F2F2F2;

	.active_sign {
	    background: $blue90;
	}

	.content {
	    color: $blueA600;

	    svg {
		path {
		    stroke: $blueA600;
		}
	    }
	}
    }
}

.active {
    .active_sign {
	background: $blueA600 !important;
    }
}

.active_sign {
    width: 6px;
    transition: all .2s ease-in-out;
}

.disabled {
    cursor: default !important;

    .content {
	color: $grey500;

	svg {
	    path {
		stroke: $grey500 !important;
	    }
	}
    }

    &:hover {
	background: transparent !important;

	.content {
	    color: $grey500;

	    svg {
		path {
		    stroke: $grey500 !important;
		}
	    }
	}

	.active_sign {
	    background: transparent !important;
	}
    }
}

.content {
    font-weight: 500;
    color: $black;
    padding: 17px 27px;
    font-size: 16px;
    display: flex;
    align-items: center;

    span {
	white-space: pre;
    }

    svg {
	margin-right: 19px;
    }
}
