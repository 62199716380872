@import "/src/styles/color";

.modal {
	position: fixed;
	top: 122px;
	left: 100px;
	z-index: 130;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Inter, sans-serif;
	text-align: left;
	max-width: 700px;
	-webkit-box-shadow: 3px 2px 12px -6px rgba(34, 60, 80, 0.46);
	-moz-box-shadow: 3px 2px 12px -6px rgba(34, 60, 80, 0.46);
	box-shadow: 3px 2px 12px -6px rgba(34, 60, 80, 0.46);

}
.content_wrapper {
	border-radius: 12px;
	background-color: $white;
	position: relative;
}
.header {
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 4px;
	padding: 17px 24px 0 24px;
	h3 {
		margin: 0;
		font-family: Inter, sans-serif;
		font-size: 24px;
		font-weight: 600;
		line-height: 28.8px;
		text-align: left;
	}
}
.backBtn {
	padding: 4px;
	margin-left: -6px;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	cursor: pointer;
	path {
		stroke: rgba(39, 39, 39, 1);
	}
}
.closeBtn {
	cursor: pointer;
	align-self: flex-end;
	margin-left: auto; /* Перемещение последнего элемента вправо */
}


.modal_enter {
	opacity: 0;
	transform: scale(1);
}

.modal_enter_active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 400ms, transform 400ms;
}

.modal_exit {
	opacity: 1;
	pointer-events: none;
	transition: all 400ms;
}

.modal_exit_active {
	opacity: 0;
	transition: all 400ms;
}
