@import "/src/styles/color";

//.modals, .modal_active {
//    height: 100vh;
//    width: 100vw;
//    background-color: rgba(0, 0, 0, 0.4);
//    position: fixed;
//    top: 0;
//    left: 0;
//    z-index: 1000;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    opacity: 0;
//    pointer-events: none;
//    transition: 0.4s all;
//}
//
//.modal_active {
//    opacity: 1;
//    pointer-events: all;
//    transition: 0.4s all;
//}
//
//.content, .content_active {
//    padding: 24px;
//    border-radius: 12px;
//    background-color: #fff;
//    transition: 0.4s all;
//}
//
//
//.content_active {
//    transform: scale(1);
//}


.modal {
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.content_wrapper {
	padding: 30px;
	border-radius: 12px;
	background-color: $white;
	position: relative;
}

.modal_enter {
	opacity: 0;
	transform: scale(1);
}

.modal_enter_active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 400ms, transform 400ms;
}

.modal_exit {
	opacity: 1;
	pointer-events: none;
	transition: all 400ms;
}

.modal_exit_active {
	opacity: 0;
	transition: all 400ms;
}
