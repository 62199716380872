@import '/src/styles/color';

.wrapper {
    gap: 16px;
    width: 100%;
    padding: 8px;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    align-items: center;
    transition: all 0.3s ease;
    border: 2px solid transparent;

    &:hover {
	color: $white;
	background-color: $blueA600;
    }
}

.picture {
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.name {
    font-weight: 400;
}

.filled {
    color: $white;
    background-color: $blueA600;
}

.bright-bordered {
    border: 2px solid $blueA600;
}

.light-bordered {
    border: 2px solid #CEE4FF;
}

.bright-bordered, .light-bordered {
    border-radius: 8px;
}

