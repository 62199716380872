@import "./_title";
@import "_tools";
@import "_orientation";
@import "./_item";
@import "./_colorInput";
@import "_repeating-image-size";
@import "_repeating-image-size-input";
@import "_repeating-image-size-label";

.page-properties {
	background: #FFFFFF;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 24px;
	margin-bottom: 8px;
	display: none;
	z-index: 1000;
}
