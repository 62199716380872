@import "../../base/default_variables";

.panel-action__forward {
    @extend %tpl_Tool;
    stroke: #CDCDCD;
    display: flex;
    justify-content: center;
    margin: 0 !important;
    &:hover {
        stroke: $panelElementHover;
    }
    &:focus {
        stroke: $actionColorOnClick !important;
    }
}

.panel-action__forward_wake-up {
    stroke: #7F8184 !important;
}
