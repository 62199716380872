@import './_box';
@import './_close';
@import './_btn';
@import './_title';
@import './_inputs-wrapper';
@import './_input-rows';
@import './_input-columns';

.table-input-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 11;
}