@import "../../base/default_variables";

.panel-lists__order {
	@extend %tpl_Tool;
	stroke: #7F8184;
	margin: 0;
	&:hover {
		stroke: $panelElementHover;
	}
	&_active {
		stroke: $actionColorOnClick !important;
	}
}