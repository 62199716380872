@import "/src/styles/color";

.category_list {
    overflow-y: auto;
    max-height: 216px;
    width: 100%;
    margin-top: 12px;
}

.category_list::-webkit-scrollbar {
    width: 4px;
}

.category_list::-webkit-scrollbar-track {
    background: $grey200;
    border-radius: 8px;
}

.category_list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 3px solid $grey850;
}

.search_category {
    background-color: transparent;
}
