@import "/src/styles/color";

.wrapper {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.close_icon, .close_icon_margin {
    cursor: pointer;
    margin-left: 15px;
    margin-right: 30px;
    transition: all 300ms ease;

    path {
        transition: all 200ms ease;
    }

    &:hover {
        path {
            stroke: $black;
        }
    }
}

.reset_tags {
    color: #98999A;
    white-space: nowrap;
    cursor: pointer;
    font-size: 13px;
    margin-left: 16px;
}

.close_icon_margin {
    margin-right: 30px;
}

