@import "/src/styles/color";

.wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 12px 20px 12px 0;
}

.text {
    margin-left: 14px;
    font-weight: 700;
    font-size: 14px;
    color: $blueA600;
}
