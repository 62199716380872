@import "../../base/default_colors";

.object-panel__title {
	margin: 0 0 10px 0;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	color: $color_text_black;
	text-align: start;
}
