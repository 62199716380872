@import "/src/styles/color";

.wrapper {
    display: flex;
    align-items: center;
    padding: 24px;
    border: 1px solid $light-grey;
    border-bottom: none;
    cursor: pointer;
}

.main {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 35%;

    .name {
	font-weight: 500;
	font-size: 16px;
    }

    .picture {
	width: 33px;
	height: 33px;
	border-radius: 50%;
    }
}

.organization {
    width: 25%;
    font-weight: 700;
    color: $black
}

.email {
    width: 25%;
    margin-left: 40px;
    color: $light-blue
}

.organization, .email {
    font-weight: 700;
    font-size: 14px;
}

