@import "/src/styles/color";

.wrapper {
    width: 100%;
    margin-top: 32px;
}

.first_card {
    border-radius: 24px 24px 0 0;
}

.last_card {
    border: 1px solid $light-grey;
    border-radius: 0 0 24px 24px;
}

.single {
    border-radius: 24px 24px 24px 24px;
}
