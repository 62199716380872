@import '/src/styles/color';

.wrapper {
    padding: 36px 0;
}

.title {
    font-weight: 600;
    font-size: 36px;
}

.title_wrapper {
    gap: 16px;
}

.tags {
    margin-bottom: 24px;

    h3 {
	font-size: 23px;
	font-weight: 500;
	margin: 0 0 13px;
    }
}

.lock {
    justify-content: center;
    background-color: $blueA600;
    padding: 12px;
    border-radius: 50%;
}

.controls {
    flex-direction: column;
    max-width: 290px;
}

.description {
    text-align: start;
    color: $grey;
    font-weight: 700;
    font-size: 12px;
    margin-top: 16px;
    line-height: 15.6px;
}

.controls_wrapper {
    justify-content: space-between;
}

.controls_wrapper, .lock, .title_wrapper, .controls {
    display: flex;
    align-items: center;
}

